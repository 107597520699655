import React from "react";

const About = () => {
  return (
    <>
      <div id="about" className="w-full bg-white py-12 md:py-24">
        <div className="container mx-auto px-8 md:px-20 flex flex-col md:flex-row items-start justify-between">
          {/* Left Half: About Text Section */}
          <div className="w-full flex flex-col text-center">
            <h2 className="text-[18pt] md:text-[40pt] md:text-[56pt] font-bold text-center text-black mb-8">
              About AIRCHECK
            </h2>
            <p className="text-black text-center text-[12pt] md:text-[24pt] font-extralight">
              Artificial Intelligence-Ready CHEmiCal Knowledge Base (AIRCHECK)
              is an open, cloud-based platform that provides the high-quality,
              structured data AI needs to accelerate drug discovery. AIRCHECK
              helps researchers train and improve AI models to predict how small
              molecules interact with proteins.
            </p>
            <button
              className="w-3/4 md:w-1/3 mt-8 md:mt-16 mx-auto py-2 text-[12pt] md:text-[16pt] bg-gradient-to-r from-[#e53201] to-[#f36548] text-white font-bold rounded-3xl  hover:bg-[#a2d2ff] transition-colors"
              onClick={() => (window.location.href = "/readme")}
            >
              READ MORE
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
