import { useEffect, useState } from "react";

import axios from "axios";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import { ProgressSpinner } from "primereact/progressspinner";
import { doc_links } from "../../utils/constants/mapping_dict";
import "./style.css";

const Readmd = () => {
  const [documents, setDocuments] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const fetchedDocuments = {};
        for (const [docName, endpoint] of Object.entries(doc_links)) {
          const response = await axios.get(`/api/readmefile/doc/${endpoint}`);

          fetchedDocuments[docName] = response.data;
        }
        setDocuments(fetchedDocuments);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Document retrieval error: " + error);
      }
    };

    fetchDocuments();
  }, []);

  if (loading) {
    return (
      <div className="h-screen flex justify-content-center items-center">
        <ProgressSpinner
          style={{ width: "250px", height: "250px" }}
          strokeWidth="6"
        />
      </div>
    );
  }

  return (
    <>
      {/* <div className="min-h-full md:min-h-screen flex flex-col items-center justify-center py-40 md:py-24 px-8 md:px-32 bg-gray-50">
        <div className="w-full max-w-6xl"> */}
      <div className="container mx-auto px-8 py-16 pt-36 md:pt-16 md:px-16 md:py-24">
        {Object.entries(documents).map(([docName, content]) => (
          <div id={docName} key={docName} className="mt-12">
            <ReactMarkdown
              className="prose lg:prose-xl max-w-none"
              remarkPlugins={[remarkGfm]}
              components={{
                h1: ({ node, ...props }) => (
                  <h1
                    style={{
                      fontSize: "28px",
                      fontWeight: "bold",
                      marginBottom: "20px",
                      marginTop: "40px",
                    }}
                    {...props}
                  />
                ),
                h2: ({ node, ...props }) => (
                  <h2
                    style={{
                      fontSize: "20px",
                      fontWeight: "bold",
                      marginBottom: "8px",
                      marginTop: "8px",
                    }}
                    {...props}
                  />
                ),
                h3: ({ node, ...props }) => (
                  <h3
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      marginBottom: "6px",
                      marginTop: "6px",
                    }}
                    {...props}
                  />
                ),
                strong: ({ node, ...props }) => (
                  <strong style={{ fontWeight: "bold" }} {...props} />
                ),
                table: ({ node, ...props }) => (
                  <table
                    style={{ borderCollapse: "collapse", width: "100%" }}
                    {...props}
                  />
                ),
                tr: ({ node, ...props }) => (
                  <tr
                    style={{
                      borderTop: "1px solid #c6cbd1",
                      background: "#fff",
                    }}
                    {...props}
                  />
                ),
                th: ({ node, ...props }) => (
                  <th
                    style={{
                      padding: "6px 13px",
                      border: "1px solid #dfe2e5",
                    }}
                    {...props}
                  />
                ),
                td: ({ node, ...props }) => (
                  <td
                    style={{
                      padding: "6px 13px",
                      border: "1px solid #dfe2e5",
                    }}
                    {...props}
                  />
                ),
                ul: ({ node, ...props }) => (
                  <ul className="list-disc" {...props} />
                ),
                ol: ({ node, ...props }) => (
                  <ol className="list-disc" {...props} />
                ),
                li: ({ node, ...props }) => (
                  <li className="list-disc" {...props} />
                ),
              }}
            >
              {content}
            </ReactMarkdown>
          </div>
        ))}
      </div>
    </>
  );
};

export default Readmd;
