import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import axios from "axios";

import { useAuth } from "../../../context/AuthContext";
import { LoginChoiceDialog, LoginDialog, LoginDialogFooter } from "./LoginDialog";
import { SignUpDialog, SignUpDialogFooter } from "./SignupDialog";
import { ResetPasswordDialogFooter, ResetPasswordDialog } from "./ResetPasswordDialog";
import NotificationDialog from "./NotificationDialog";
import Dropdown from "./DocDropdown";
import { doc_links } from "../../../utils/constants/mapping_dict";
import MobileDropdownMenu from "./MobileDropDown";

const Navbar = () => {
  // From AuthContext.js for various firebase login methods

  // Dialog popup variables (true = show)
  const [showLoginChoiceDialog, setShowLoginChoiceDialog] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [showSignUpDialog, setShowSignUpDialog] = useState(false);
  const [showResetPasswordDialog, setShowResetPasswordDialog] = useState(false);

  // Notifications for login/signup variables
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [isError, setIsError] = useState(false); // Either success and error message

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // const [isOpen, setIsOpen] = useState(false);
  const [isAuthenticated, setAuthentication] = useState(false);
  // const [isVisible, setIsVisible] = useState(true);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setIsMobileDropdownOpen(false);
  };
  const { currentUser, loginWithGoogle, loginWithEmailPassword, signUpWithEmailPassword, resetPassword } = useAuth();

  const handleLinkClick = () => {
    setIsOpen(false); // Close the navbar when a link is clicked
    window.scrollTo(0, 0); // Scroll to the top of the page
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024); // Adjust breakpoint as needed
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const approvalCheck = async () => {
      try {
        const res = await axios.get(`/api/data/admin/${currentUser.email}`);

        if (!res || res.status !== 200) {
          setAuthentication(false);
          return;
        }
        // if (res && (res.hitgenAdmin || res.xchemAdmin)) {
        if (res && (res.data.hitgenAdmin || res.data.xchemAdmin || res.data.superAdmin)) {
          setAuthentication(true);
        }
      } catch (error) {
        console.error("Error fetching error: ", error);
      }
    };
    if (currentUser) {
      approvalCheck();
    }
  }, [currentUser, isAuthenticated]);

  const handleProfileAction = () => {
    if (currentUser) {
      // Redirect to the profile page if the user is logged in
      window.location.href = "/profile";
    } else {
      // Show login dialog if the user is not logged in
      setShowLoginChoiceDialog(true);
    }
    setIsOpen(false); // Close the navbar
  };

  const handleScrollToAbout = (e) => {
    e.preventDefault();

    if (window.location.pathname !== "/") {
      navigate("/", { state: { scrollToAbout: true } });
    } else {
      const aboutElement = document.getElementById("about");
      if (aboutElement) {
        const navbarHeight = document.querySelector("nav")?.offsetHeight || 80;
        const aboutPosition = aboutElement.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({ top: aboutPosition - navbarHeight, behavior: "smooth" });
      }
    }
    setIsOpen(false);
  };

  const handleResourcesClick = () => {
    if (isMobile) {
      setIsMobileDropdownOpen(true); // Open MobileDropdownMenu on mobile
    }
  };

  // Navbar component
  return (
    <>
      <header className="fixed top-0 left-0 w-full bg-[#06002e] shadow-lg z-10">
        <nav className="container mx-auto flex items-center md:px-16 py-3">
          {/* Logo */}
          <Link to="/" className="flex items-start md:items-center mr-2 lg:mr-4">
            <img
              src="/images/new-logos/aircheck_nav_logo.png"
              alt="AIRCHECK"
              className="max-h-40 md:max-h-16 min-w-[120px] w-auto object-contain"
              // onClick={() => window.scrollTo(0, 0)}
            />
          </Link>

          <div
            className={`${isOpen ? "block" : "hidden"} lg:flex flex-1 justify-between w-full lg:w-auto mt-4 lg:mt-0`}
          >
            {/* Navigation Links */}
            <ul className="flex flex-col lg:flex-row items-center space-y-10 lg:space-y-0 lg:space-x-4">
              {/* Solutions Dropdown */}
              <li className="relative group">
                <Link
                  to="/#about"
                  className="text-white hover:text-[#e6cb64] transition duration-200 px-2 pb-1"
                  onClick={handleScrollToAbout}
                >
                  About
                  <span className="absolute left-0 bottom-[-8px] w-0 h-1 bg-[#e6cb64] group-hover:w-full transition-all duration-300"></span>
                </Link>
              </li>

              <li className="relative group">
                <Link
                  to="/models"
                  className="text-white hover:text-[#e6cb64] transition duration-200  pb-1"
                  onClick={handleLinkClick}
                >
                  Pipeline
                  <span className="absolute left-0 bottom-[-8px] w-0 h-1 bg-[#e6cb64] group-hover:w-full transition-all duration-300"></span>
                </Link>
              </li>

              <li className="relative group">
                <Link
                  to="/datasets"
                  className="text-white hover:text-[#e6cb64] transition duration-200 px-2  block"
                  onClick={handleLinkClick}
                >
                  Datasets
                  <span className="absolute left-0 bottom-[-8px] w-0 h-1 bg-[#e6cb64] group-hover:w-full transition-all duration-300"></span>
                </Link>
              </li>

              {/* <Dropdown setIsOpen={setIsOpen} /> */}
              <Dropdown
                setIsMobileDropdownOpen={setIsMobileDropdownOpen}
                doc_links={doc_links}
                handleClick={handleLinkClick}
                isMobile={isMobile}
                setIsOpen={setIsOpen}
                handleResourcesClick={handleResourcesClick} // Pass the function here
              />
              {/* {currentUser && (
                <li className="relative group">
                  <Link
                    to="/application"
                    className="text-white hover:text-[#e6cb64] transition duration-200 pb-1"
                    onClick={handleLinkClick}
                  >
                    Apply for Datasets
                    <span className="absolute left-0 bottom-[-8px] w-0 h-1 bg-[#e6cb64] group-hover:w-full transition-all duration-300"></span>
                  </Link>
                </li>
              )} */}

              {currentUser && isAuthenticated && (
                <li className="relative group">
                  <Link
                    to="/application-list"
                    className="text-white hover:text-[#e6cb64] transition duration-200 pb-1"
                    onClick={handleLinkClick}
                  >
                    Application List
                    <span className="absolute left-0 bottom-[-8px] w-0 h-1 bg-[#e6cb64] group-hover:w-full transition-all duration-300"></span>
                  </Link>
                </li>
              )}
            </ul>

            {/* Action Buttons */}
            <div className="flex flex-col lg:flex-row items-center space-y-4 lg:space-y-0 lg:space-x-6 mt-4 lg:mt-0 px-2">
              <a
                href="https://event.fourwaves.com/aircheckworkshop2025/pages"
                className="bg-gradient-to-r from-[#e53201] to-[#f36548] text-white bg-green-600 rounded-full px-4 py-2 hover:bg-[#303030] hover:text-white  transition duration-200"
                rel="oopener noreferrer"
                target="_blank"
                onClick={handleLinkClick}
              >
                AIRCHECK Workshop
              </a>
              <div className="relative inline-block">
                <button
                  // onClick={handleAuthAction}
                  onClick={handleProfileAction}
                  // className=" bg-gradient-to-r from-[#e53201] to-[#f36548] text-white font-bold border-2 border-[#67be78] rounded-full px-4 py-2 hover:bg-[#f5ffe4] hover:text-black transition duration-200 w-full"
                  className="text-white font-bold  rounded-full px-4 py-2 font-bold border-2 border-[#f36548] hover:bg-[#f36548] hover:text-black transition duration-200 w-full"
                >
                  {currentUser ? "View Profile" : "Log In"}
                </button>

                {/* {currentUser && (
                  <span className="absolute left-1/2 -translate-x-1/2 top-full  text-white text-sm px-3 md:py-1 rounded-md shadow-md whitespace-nowrap">
                    {currentUser.email}
                  </span>
                )} */}
              </div>
            </div>
          </div>

          {/* Mobile Menu Button - Visible only on small screens */}
          <button
            className="lg:hidden flex items-center pr-4 text-white hover:text-white focus:outline-none"
            onClick={toggleMenu}
            aria-label="Toggle navigation"
          >
            <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </nav>
        {isMobileDropdownOpen && (
          <MobileDropdownMenu
            setIsMobileDropdownOpen={setIsMobileDropdownOpen}
            doc_links={doc_links}
            handleClick={handleLinkClick}
            setIsOpen={setIsOpen}
          />
        )}
      </header>

      <Dialog
        header="Choose Login Method"
        visible={showLoginChoiceDialog}
        style={{ width: "500px" }}
        onHide={() => setShowLoginChoiceDialog(false)}
      >
        <LoginChoiceDialog
          loginWithGoogle={loginWithGoogle}
          setShowLoginChoiceDialog={setShowLoginChoiceDialog}
          setShowSignUpDialog={setShowSignUpDialog}
          setShowLoginDialog={setShowLoginDialog}
          setShowResetPasswordDialog={setShowResetPasswordDialog}
        />
      </Dialog>
      <Dialog
        header="Login"
        visible={showLoginDialog}
        style={{ width: "500px" }}
        footer={
          <LoginDialogFooter
            email={email}
            password={password}
            loginWithEmailPassword={loginWithEmailPassword}
            setNotificationMessage={setNotificationMessage}
            setIsError={setIsError}
            setShowNotification={setShowNotification}
            setShowResetPasswordDialog={setShowResetPasswordDialog}
            setShowLoginDialog={setShowLoginDialog}
            setShowSignUpDialog={setShowSignUpDialog}
          />
        }
        onHide={() => setShowLoginDialog(false)}
      >
        <LoginDialog email={email} setEmail={setEmail} password={password} setPassword={setPassword} />
      </Dialog>
      <Dialog
        header="Sign Up"
        visible={showSignUpDialog}
        style={{ width: "500px" }}
        footer={
          <SignUpDialogFooter
            name={name}
            email={email}
            password={password}
            signUpWithEmailPassword={signUpWithEmailPassword}
            setShowSignUpDialog={setShowSignUpDialog}
            setNotificationMessage={setNotificationMessage}
            setShowNotification={setShowNotification}
            setIsError={setIsError}
            setShowLoginChoiceDialog={setShowLoginChoiceDialog}
            setShowLoginDialog={setShowLoginDialog}
            setShowResetPasswordDialog={setShowResetPasswordDialog}
          />
        }
        onHide={() => setShowSignUpDialog(false)}
      >
        {
          <SignUpDialog
            name={name}
            setName={setName}
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
          />
        }
      </Dialog>
      <Dialog
        header="Reset Password"
        visible={showResetPasswordDialog}
        style={{ width: "500px" }}
        footer={
          <ResetPasswordDialogFooter
            email={email}
            resetPassword={resetPassword}
            setNotificationMessage={setNotificationMessage}
            setIsError={setIsError}
            setShowNotification={setShowNotification}
            setShowLoginDialog={setShowLoginDialog}
            setShowResetPasswordDialog={setShowResetPasswordDialog}
            setShowLoginChoiceDialog={setShowLoginChoiceDialog}
            setShowSignUpDialog={setShowSignUpDialog}
          />
        }
        onHide={() => setShowResetPasswordDialog(false)}
      >
        <ResetPasswordDialog email={email} setEmail={setEmail} />
      </Dialog>
      <Dialog
        header={!isError ? "Success" : "Failure"}
        visible={showNotification}
        style={{ width: "300px" }}
        onHide={() => setShowNotification(false)}
      >
        <NotificationDialog notificationMessage={notificationMessage} isError={isError} />
      </Dialog>
    </>
  );
};

export default Navbar;
