import React from "react";

const DatabaseDetails = () => {
  const details = [
    {
      Id: 1,
      src: "/images/icons/dbsets/icon1.png",
      description:
        "Advanced screening technology to test billions of molecules with high precision.",
    },
    {
      Id: 2,
      src: "/images/icons/dbsets/icon3.png",
      description:
        "Reliable experimental data to ensure AI predictions are accurate and reproducible.",
    },
    {
      Id: 3,
      src: "/images/icons/dbsets/icon2.png",
      description:
        "AI-ready datasets with detailed positive (binding) and negative (non-binding) interactions.",
    },
    {
      Id: 4,
      src: "/images/icons/dbsets/icon4.png",
      description:
        "Ongoing validation to refine AI models with real-world results.",
    },
    {
      Id: 5,
      src: "/images/icons/dbsets/newdatabase2.png",
      description:
        "Global collaboration with industry and academia to expand data access.",
    },
    {
      Id: 6,
      src: "/images/icons/dbsets/new_cloudbased.png",
      description:
        "Cloud-based platform for seamless AI-driven drug discovery.",
    },
  ];

  return (
    <div className="w-full bg-white shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1),0_-2px_4px_-1px_rgba(0,0,0,0.06)] py-12 md:my-24 md:py-20">
      <div className="container mx-auto md:pl-20 md:pr-10">
        <p className="text-[18pt] md:text-[56pt] text-center font-bold text-black">
          More than just a database
        </p>

        <div className="grid grid-cols-1 justify-start sm:grid-cols-2 md:grid-cols-3 md:gap-6 mt-8 md:mt-12 md:pr-8">
          {details.map((item) => (
            <div
              key={item.Id}
              className="flex flex-col px-8 md:px-4 justify-left rounded-lg md:py-4 md:shadow-lg  transition-shadow"
            >
              <div className="flex flex-row md:flex-col mb-4">
                <img
                  src={item.src}
                  alt={`Icon for ${item.text}`}
                  className="w-8 h-8 md:w-16 md:h-16 object-contain"
                />
                <p className="text-[12pt] pl-4 md:text-[20pt] text-black font-extralight">
                  {item.description}
                </p>
              </div>
              {/* <div className="text-left ">
                <p className="text-[12pt] md:text-[20pt] text-black font-extralight">
                  {item.description}
                </p>
              </div> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DatabaseDetails;
