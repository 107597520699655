// dropdown items to be populated in feedback page
export const labels_dict = [
  { name: "Bug", code: "bug" },
  { name: "Documentation", code: "documentation" },
  { name: "Enhancement", code: "enhancement" },
  { name: "Invalid", code: "invalid" },
  { name: "Question", code: "question" },
];

export const doc_links = {
  AIRCHECK: "AIRCHECK.md",
  "Chemical Representation": "Chemical-Representation.md",
  DEL: "DEL.md",
  "Data Overview": "Data-Overview.md",
  "EAS-MS": "EASMS.md",
  "Starting with ML": "Starting-with-ML.md",
};

export const ml_models = {
  models: [
    {
      name: "DEL-ML-WDR91",
      description: "A model that successfully predicted active binders to the WDR91 protein.",
      link: "https://github.com/jimmyjbling/SGC-DEL-ML-WDR91/tree/main",
    },
  ],
};

export const DATASOURCE_DATA = {
  data: [
    {
      id: "HitGen",
      name: "HitGen",
      description: "Description for Data Source A",
    },
    {
      id: "X-Chem",
      name: "X-Chem",
      description: "Description for Data Source B",
    },
    {
      id: "ASMS",
      name: "ASMS",
      description: "Description for Data Source C",
    },
  ],
};

export const Targets = {
  data: {
    hitgen: [
      {
        id: "wdr91",
        name: "WDR91",
        description: "Description for Target A1",
      },
    ],
    xchem: [
      {
        id: "B1",
        name: "Target B1",
        description: "Description for Target B1",
      },
      {
        id: "B2",
        name: "Target B2",
        description: "Description for Target B2",
      },
    ],
    asms: [
      {
        id: "C1",
        name: "Target C1",
        description: "Description for Target C1",
      },
      {
        id: "C2",
        name: "Target C2",
        description: "Description for Target C2",
      },
      {
        id: "C3",
        name: "Target C3",
        description: "Description for Target C3",
      },
      {
        id: "C4",
        name: "Target C4",
        description: "Description for Target C4",
      },
    ],
  },
};
