import React from "react";
import { Link as ScrollLink } from "react-scroll";
import { useNavigate } from "react-router-dom";
const MobileDropdownMenu = ({ setIsMobileDropdownOpen, doc_links, setIsOpen }) => {
  const navigate = useNavigate();

  const handleClick_here = (label) => {
    setIsOpen(false);
    setIsMobileDropdownOpen(false); // Close the navbar

    navigate("/readme"); // Navigate to /readme
  };
  return (
    <div className="fixed top-0 left-0 w-full mt-4 h-full bg-[#06002e] z-20 transform transition-transform duration-300 ease-in-out lg:hidden">
      <div className="container mx-auto item-center text-center px-4 py-3">
        <div className="flex justify-between items-center mb-4">
          <button
            onClick={() => setIsMobileDropdownOpen(false)}
            className="text-white hover:text-[#e6cb64] transition duration-200 flex items-center"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>

          <span className="text-white font-bold text-xl">Resources</span>

          <button
            onClick={() => setIsMobileDropdownOpen(false)}
            className="text-white hover:text-[#e6cb64] transition duration-200"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <ul className="space-y-4">
          {Object.entries(doc_links).map(([label, href_link]) => (
            <li key={label}>
              <ScrollLink
                to={label}
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onClick={() => {
                  handleClick_here(label);
                  setIsMobileDropdownOpen(false);
                }}
                className="block text-white hover:bg-blue-600 hover:text-[#e6cb64] transition duration-300 px-4 py-2 text-sm"
              >
                {label}
              </ScrollLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MobileDropdownMenu;
