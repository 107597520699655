import React, { useState, useRef } from "react";

import { InputText } from "primereact/inputtext";
import axios from "axios";
import Swal from "sweetalert2";
import { useMountEffect } from "primereact/hooks";
// import { Messages } from "primereact/messages";

import { InputTextarea } from "primereact/inputtextarea";
import { Formik, Field } from "formik";

import { ProgressSpinner } from "primereact/progressspinner";
import { labels_dict } from "../utils/constants/mapping_dict";

const FeedbackForm = () => {
  const [loading, setLoading] = useState(false);

  const initialValues = {
    title: "",
    issue: "",
    issue_label: "",
    email: "",
  };

  const msgs = useRef(null);

  useMountEffect(() => {
    if (msgs.current) {
      msgs.current.clear();
      msgs.current.show({
        id: "1",
        sticky: true,
        severity: "info",
        summary: "Info",
        detail: `Your feedback will be submitted via the AIRCHECK-DEL username on our GitHub repository as Github Issue.`,
        closable: false,
      });
    }
  });

  const validate = (values) => {
    const errors = {};
    // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const regex = /^[a-zA-Z][^\s@]*@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.title) {
      errors.title = "Title is required";
    }
    if (values.email && !regex.test(values.email)) {
      errors.email = "Invalid email format";
    }

    if (!values.issue) {
      errors.issue = "Feedback description is required";
    }

    if (!values.issue_label) {
      errors.issue_label = "Label is required";
    }

    return errors;
  };

  const submitForm = async (values) => {
    setLoading(true);
    // const issueBody = values.email
    //   ? `${values.issue}\n Email: ${values.email}`
    //   : values.issue;

    try {
      const issues = {
        title: values.title,
        body: values.issue,
        // body: issueBody,
        labels: [values.issue_label],
      };
      const issue_db = {
        title: values.title,
        body: values.issue,
        email: values.email,
        labels: [values.issue_label],
      };

      const res1 = await axios.post("api/feedback/submit_issue/", issue_db);

      // return;
      const res = await axios.post("api/feedback/git_issue/", issues);
      if (res.status === 201 && res1.status === 200) {
        Swal.fire({
          title: "Success!",
          text: "Issue created successfully",
          icon: "success",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          customClass: {
            confirmButton:
              "bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/datasets"; // Navigate to the home page
          }
        });

        // alert("Issue created successfully");
        // navigate("/datasets");
      } else {
        Swal.fire({
          title: "Error!",
          text: res.status,
          icon: "Error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          customClass: {
            confirmButton:
              "bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded",
          },
        });
        // alert(`Error during form submission: ${res.status}`);
      }
    } catch (error) {
      // console.error("Error during form submission:", error);
      Swal.fire({
        title: "Error!",
        text: "Error during form submission",
        icon: "Error",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        customClass: {
          confirmButton:
            "bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded",
        },
      });
      if (error.response) {
        // alert(`Error during form submission: ${error.response.status}`);
        Swal.fire({
          title: "Error!",
          text: "Error during form submission",
          icon: "Error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          customClass: {
            confirmButton:
              "bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded",
          },
        });
      } else if (error.request) {
        alert("No response received from server");
      } else {
        alert("Error setting up request");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    // <div className="w-full h-screen mt-[12rem] md:mt-[6.5rem]">
    <div className="container mx-auto px-8 pt-36 md:px-16 md:py-32">
      {/* Your existing content goes here */}
      <div className="text-center">
        <h2 className="text-3xl font-extrabold text-black sm:text-4xl">
          We Value Your Feedback
        </h2>
        <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
          Help us improve our services by sharing your thoughts and experiences
        </p>
      </div>

      <div className="mt-10 bg-white overflow-hidden shadow rounded-lg w-full">
        <div className="grid md:grid-cols-2 gap-0">
          {/* Image Section */}
          <div className="bg-[#4a3b3bad] flex flex-col justify-center items-center p-8">
            <div className="text-center">
              <img
                src="/images/logos/feedback.png"
                alt="Feedback"
                className="mx-auto h-64 w-auto mb-6"
              />
              <h3 className="text-2xl font-bold text-white mb-4">
                Your Opinion Matters
              </h3>
              <p className="text-blue-100 max-w-md mx-auto">
                We're constantly working to improve our platform based on your
                feedback. Every submission helps us create a better experience
                for all users.
              </p>

              <div className="mt-8 grid grid-cols-3 gap-4 text-center">
                <div className="bg-gradient-to-r from-[#e53201] to-[#f36548] text-white px-4 py-2 rounded-3xl  hover:bg-[#a2d2ff] transition-colors">
                  <p className="md:text-3xl font-bold text-white">24h</p>
                  <p className="text-white text-sm">Response Time</p>
                </div>
                <div className="bg-gradient-to-r from-[#e53201] to-[#f36548] text-white px-4 py-2 rounded-3xl  hover:bg-[#a2d2ff] transition-colors">
                  <p className="md:text-3xl font-bold text-white">100%</p>
                  <p className="text-white text-sm">Review Rate</p>
                </div>
              </div>
            </div>
          </div>

          {/* Form Section */}
          <div className="p-8">
            <Formik
              initialValues={initialValues}
              validate={validate}
              onSubmit={submitForm}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                errors,
                touched,
                handleBlur,
                isValid,
                dirty,
              }) => (
                <form onSubmit={handleSubmit} className="space-y-6">
                  {loading ? (
                    <div className="h-64 flex justify-center items-center">
                      <ProgressSpinner
                        style={{ width: "80px", height: "80px" }}
                        strokeWidth="6"
                      />
                    </div>
                  ) : (
                    <>
                      <div>
                        <label className="block text-gray-700 font-bold mb-2 after:content-['*'] after:ml-1 after:text-red-500">
                          Feedback type
                        </label>
                        <Field
                          name="issue_label"
                          as="select"
                          className="shadow border rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
                        >
                          <option value="">Select feedback type</option>
                          {labels_dict.map((label_name) => (
                            <option
                              key={label_name.code}
                              value={label_name.code}
                            >
                              {label_name.name}
                            </option>
                          ))}
                        </Field>
                        {errors.issue_label && touched.issue_label && (
                          <p className="mt-2 text-sm text-red-600">
                            {errors.issue_label}
                          </p>
                        )}
                      </div>

                      <div>
                        <label className="block text-gray-700 font-bold mb-2 after:content-['*'] after:ml-1 after:text-red-500">
                          Feedback title
                        </label>
                        <InputText
                          id="title"
                          type="text"
                          value={values.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="shadow border rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
                        />
                        {errors.title && touched.title && (
                          <p className="mt-2 text-sm text-red-600">
                            {errors.title}
                          </p>
                        )}
                      </div>

                      <div>
                        <label className="block text-gray-700 font-bold mb-2">
                          Email
                        </label>
                        <p className="text-xs text-gray-500 mb-2">
                          If you want a follow up please provide the best email
                          to contact you
                        </p>
                        <InputText
                          id="email"
                          type="email"
                          value={values.email}
                          onChange={handleChange}
                          className="shadow border rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
                        />
                        {errors.email && touched.email && (
                          <p className="mt-2 text-sm text-red-600">
                            {errors.email}
                          </p>
                        )}
                      </div>

                      <div>
                        <label className="block text-gray-700 font-bold mb-2 after:content-['*'] after:ml-1 after:text-red-500">
                          Description
                        </label>
                        <InputTextarea
                          id="issue"
                          rows={4}
                          value={values.issue}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="shadow border rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150"
                          placeholder="Ex: I could not download x dataset after having my application approved"
                        />
                        {errors.issue && touched.issue && (
                          <p className="mt-2 text-sm text-red-600">
                            {errors.issue}
                          </p>
                        )}
                      </div>

                      <div className="flex justify-center pt-4">
                        <button
                          type="submit"
                          className={`text-sm  md:text-xl mb-2 flex bg-gradient-to-r from-[#e53201] to-[#f36548] text-white px-4 py-2 rounded-3xl  hover:bg-[#a2d2ff] transition-colors ${
                            !(dirty && isValid) &&
                            "opacity-50 cursor-not-allowed"
                          }`}
                          disabled={!(dirty && isValid)}
                        >
                          Submit Feedback
                        </button>
                      </div>
                    </>
                  )}
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackForm;
