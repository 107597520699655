import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { Chart } from "primereact/chart";
import Swal from "sweetalert2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS } from "chart.js";
import { ProgressSpinner } from "primereact/progressspinner";

ChartJS.register(ChartDataLabels);

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const fetchModelStats = async ({ queryKey }) => {
  const [_, runId] = queryKey; // Extract runId from queryKey
  if (!runId) return null;

  try {
    const response = await axios.post("/api//model_serving/mlstats", {
      runId: runId,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching model stats:", error);
    throw error;
  }
};

const ModelDetailsPopup = ({ visible, onHide, runId, targetName }) => {
  const {
    data: chartData,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["modelStats", runId],
    queryFn: fetchModelStats,
    enabled: !!runId, // Fetch only if runId is available
    placeholderData: { result: [] }, // Show placeholder data while loading
    retry: false,

    select: (data) => {
      if (!data.result) return null;
      const filtered_data = data.result.filter((stat) => stat.value > 0.2);
      const label = filtered_data.map((stat) => stat.key);
      const data_val = filtered_data.map((stat) => stat.value);
      return {
        labels: label,
        datasets: [
          {
            label: "Model Metrics",
            backgroundColor: "rgba(179,181,198,0.2)",
            borderColor: "rgb(57, 70, 189)",
            pointBackgroundColor: "rgb(55, 106, 134)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(179,181,198,1)",
            data: data_val,
          },
        ],
      };
    },
  });

  if (isError) {
    Swal.fire({
      title: "Error!",
      text: "Failed to fetch model stats. Please try again later.",
      icon: "error",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      customClass: {
        confirmButton:
          "bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "/models";
      }
    });

    return null; // Prevent further rendering of the dialog
  }

  if (!runId) return null;
  return (
    <>
      <>
        <Dialog
          header="Model Statistics"
          visible={visible}
          maximizable
          style={{ width: "80vw", height: "90vh" }}
          contentStyle={{ height: "100%", overflow: "hidden" }}
          onHide={onHide}
        >
          <div className="h-full flex flex-col">
            <h2 className="text-xl font-semibold mb-4">
              Visual Insights: How Baseline <b>{targetName}</b> Model Performs
              Across Various Evaluation Metrics
            </h2>
            {isFetching ? (
              <div className="flex justify-center items-center h-full">
                <ProgressSpinner />
              </div>
            ) : isError ? (
              <div className="flex justify-center items-center text-red-500">
                Error loading model stats.
              </div>
            ) : (
              <div className="space-y-4">
                {chartData && (
                  <div className="h-[60vh] w-[80vw] sm:h-[70vh] sm:w-[70vw]">
                    <Chart
                      type="radar"
                      data={chartData}
                      options={{
                        scales: {
                          r: {
                            beginAtZero: true,
                            ticks: {
                              display: false,
                            },
                            pointLabels: {
                              display: false,
                            },
                          },
                        },
                        plugins: {
                          datalabels: {
                            color: "#000", // Label text color
                            anchor: "center", // Position of the label relative to the point
                            align: "top", // Horizontal alignment of the label
                            formatter: (value, context) => {
                              if (value > 0.2) {
                                const label =
                                  context.chart.data.labels[context.dataIndex];
                                // Display the label as the value of the point
                                return `${label}\n${value.toFixed(2)}`; // Format the value to 2 decimal places
                              }
                              return "";
                            },
                            font: {
                              size: 12,
                              weight: "bold",
                            },
                          },
                        },
                        responsive: true, // Make the chart responsive
                        maintainAspectRatio: false, // Allow the chart to fill the container
                      }}
                      style={{ height: "100%", width: "100%" }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </Dialog>
      </>
    </>
  );
};

export default ModelDetailsPopup;
