import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/AuthContext";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  getAuth,
  sendPasswordResetEmail,
  onAuthStateChanged,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";

import Swal from "sweetalert2";

const ProfilePage = () => {
  const { currentUser, logOut } = useAuth();

  const auth = getAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [applicationId, setApplicationId] = useState(null);

  const handleAuthAction = () => {
    if (currentUser) {
      logOut(); // Call the logout function if the user is logged in
      window.location.href = "/";
    } else {
      Swal.fire({
        title: "Failed!",
        text: "Something went wrong. Please try again.",
        icon: "failed",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        customClass: {
          confirmButton:
            "bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/profile"; // Navigate to the home page
        }
      });
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        // alert("You are not logged in. Redirecting to login...");
        Swal.fire({
          title: "Access Denied",
          text: "You must be logged in to view this page",
          icon: "error",
          confirmButtonText: "Go to Homepage",
          allowOutsideClick: false,
          customClass: {
            confirmButton:
              "bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/");
          }
        });
      } else {
        const checkApplication = async () => {
          try {
            const res = await axios.get(`/api/application/email/${user.email}`);
            setApplicationId(res.data.applicationId);
            // setCurrentUser(user);
          } catch (error) {
            console.error("Error fetching application: ", error);
            setApplicationId(null);
          } finally {
            setLoading(false);
          }
        };
        checkApplication();
      }
    });

    return () => unsubscribe(); // Cleanup on unmount
  }, [auth, navigate, setApplicationId]);

  const handleResetPassword = async () => {
    if (!currentUser.email) {
      Swal.fire({
        title: "Failed!",
        text: "Failed to send reset link. Try again.",
        icon: "failed",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        customClass: {
          confirmButton:
            "bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/profile"; // Navigate to the home page
        }
      });
    }

    try {
      await sendPasswordResetEmail(auth, currentUser.email);

      Swal.fire({
        title: "Success!",
        text: "Password reset link sent successfully! Check your email.",
        icon: "success",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        customClass: {
          confirmButton:
            "bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/profile"; // Navigate to the home page
        }
      });
    } catch (error) {
      Swal.fire({
        title: "Failed!",
        text: error.message || "Failed to send reset link. Try again.",
        icon: "failed",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        customClass: {
          confirmButton:
            "bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/profile"; // Navigate to the home page
        }
      });
    }
  };
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <ProgressSpinner
          style={{ width: "250px", height: "250px" }}
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
        />
      </div>
    );
  }

  return (
    <div className="w-full bg-white shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1),0_-2px_4px_-1px_rgba(0,0,0,0.06)] mt-24 md:mt-12 py-32 md:my-24 md:py-24">
      <div className="container mx-auto pl-8 pr-8 md:pl-20 md:pr-16">
        <p className="text-3xl md:text-5xl text-center font-extralight text-black mb-8">
          Your Profile
        </p>

        <div className="grid grid-cols-1 lg:grid-cols-3">
          {/* Profile Picture Section */}
          <div className="lg:col-span-1">
            <div className="bg-gray-50 md:p-6 rounded-lg shadow-md">
              <div className="flex flex-col gap-4 items-center">
                <div className="w-40 h-40 mb-4 relative">
                  <img
                    src={currentUser?.photoURL || "/images/logos/user.png"}
                    alt="Profile"
                    onError={(e) => {
                      e.target.src = "/images/logos/user.png"; // Fallback if the image fails to load
                    }}
                    className="w-full h-full rounded-full object-cover shadow-md"
                  />
                </div>
                <h2 className="text-2xl font-medium text-gray-800">
                  {currentUser?.displayName}
                </h2>
                <div className="flex flex-col gap-4">
                  {applicationId ? (
                    <Link to={`/view-application/${applicationId}`}>
                      <button className="text-lg md:text-xl flex justify-center  bg-gradient-to-r from-[#e53201] to-[#f36548] text-white px-6 py-2 rounded-3xl  hover:bg-[#a2d2ff] transition-colors">
                        My Application
                      </button>
                    </Link>
                  ) : (
                    <Link to={"/application"}>
                      <button className="text-lg md:text-xl flex justify-center  bg-gradient-to-r from-[#e53201] to-[#f36548] text-white px-6 py-2 rounded-3xl  hover:bg-[#a2d2ff] transition-colors">
                        Apply for Datasets
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Profile Details Section */}
          <div className="lg:col-span-2">
            <div className="bg-gray-50 p-6  rounded-lg shadow-md">
              <h3 className="text-xl font-medium text-gray-800 mb-4">
                Profile Information
              </h3>

              <div className="space-y-4">
                <div className="flex border-b border-gray-200 pb-4">
                  <span className="font-medium text-gray-600 w-1/3">
                    Full Name:
                  </span>
                  <span className="text-gray-800">
                    {currentUser?.displayName}
                  </span>
                </div>
                <div className="flex border-b border-gray-200 pb-4">
                  <span className="font-medium text-gray-600 w-1/3">
                    Email:
                  </span>
                  <span className="text-gray-800">{currentUser?.email}</span>
                </div>
                <div className="flex border-b border-gray-200 pb-4">
                  <span className="font-medium text-gray-600 w-1/3">
                    Created Time:
                  </span>
                  <span className="text-gray-800">
                    {currentUser?.metadata?.creationTime}
                  </span>
                </div>
                <div className="flex border-b border-gray-200 pb-4">
                  <span className="font-medium text-gray-600 w-1/3">
                    Last Login Time:
                  </span>
                  <span className="text-gray-800">
                    {currentUser?.metadata?.lastSignInTime}
                  </span>
                </div>
                <div className="flex flex-col md:flex-row gap-4">
                  <button
                    onClick={handleResetPassword}
                    className="text-lg md:text-xl flex justify-center  bg-gradient-to-r from-[#e53201] to-[#f36548] text-white px-6 py-2 rounded-3xl  hover:bg-[#a2d2ff] transition-colors"
                  >
                    Reset Password
                  </button>
                  <button
                    onClick={handleAuthAction}
                    className="text-lg md:text-xl flex justify-center  bg-gradient-to-r from-[#e53201] to-[#f36548] text-white px-6 py-2 rounded-3xl  hover:bg-[#a2d2ff] transition-colors"
                  >
                    Log Out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
