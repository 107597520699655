import { React, useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { useAuth } from "../../context/AuthContext";
import { Dialog } from "primereact/dialog";

import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";

import axios from "axios";
import { Tooltip } from "primereact/tooltip";
import { HitgenPDFLink, XchemPDFLink } from "../terms_and_conditions/ReadPdf";
import { ProgressSpinner } from "primereact/progressspinner";
import { CustomDialog } from "../../components/common/dialog/CustomDialog";
import Swal from "sweetalert2";

const ApplicationInformation = ({
  application,
  applicationId,
  adminInformation,
  rejectJustification,
  setJustification,
}) => {
  const [users, setApplicationUsers] = useState([...(application.users || [])]);
  const [hitgenAccepted, setHitgenAccepted] = useState(
    application?.termsAndConditions?.hitgenAccepted
  );
  const [xchemAccepted, setXchemAccepted] = useState(
    application?.termsAndConditions?.xchemAccepted
  );

  const { currentUser } = useAuth();

  const [adminFirstName, setAdminFirstName] = useState(
    application?.submittedInfo?.adminFirstName
  );
  const [adminLastName, setAdminLastName] = useState(
    application?.submittedInfo?.adminLastName
  );
  const individualApplication = application?.individualApplication;
  const applicationSubmittedData = application?.dateSubmitted;
  let newPaddedDate = "";
  if (applicationSubmittedData) {
    const [year, month, day] = new Date(applicationSubmittedData)
      .toISOString()
      .split("T")[0]
      .split("-")
      .map(Number);
    const pMonth = month.toString().padStart(2, "0");
    const pDay = day.toString().padStart(2, "0");
    newPaddedDate = `${year}-${pMonth}-${pDay}`;
  }

  const [orgName, setOrgName] = useState(application?.submittedInfo?.orgName);
  const [orgAddress, setOrgAddress] = useState(
    application.submittedInfo?.orgAddress
  );
  const [businessLine, setBusinessLine] = useState(
    application?.submittedInfo?.businessLine
  );
  const [useDescription, setUseDescription] = useState(
    application?.submittedInfo?.useDescription
  );
  const [openedEULAXchem, setOpenedEULAXchem] = useState(false);
  const [openedEULAHitgen, setOpenedEULAHitgen] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [visibleConfirm, setVisibleConfirm] = useState(false);

  const [rejectionMsg, setRejectionMsg] = useState(
    application?.decision?.xchemJustification || ""
  );

  const decisionMessage = application?.decision?.xchemDecisionMessage ?? "";

  useEffect(() => {
    if (!rejectionMsg) {
      setJustification(rejectJustification);
    }
  }, [rejectionMsg, rejectJustification, setJustification]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setRejectionMsg(newValue);
    setJustification(newValue);
  };

  const checkDuplicateEmails = (users, submitterEmail) => {
    const emailCount = {};

    // Count the occurrences of each email
    users.forEach((user) => {
      const email = user.email.toLowerCase();

      emailCount[email] = (emailCount[email] || 0) + 1;
    });

    // Check if any email appears more than once
    for (const email in emailCount) {
      if (emailCount[email] > 1 || email === "submitterEmail") {
        return true; // At least 2 users have the same email
      }
    }

    return false; // No duplicate emails found
  };

  const checkWithSubmitterEmail = (users, submitterEmail) => {
    if (!users || !Array.isArray(users) || !submitterEmail) {
      throw new Error(
        "Invalid arguments: users must be an array, and submitterEmail is required."
      );
    }

    // Convert submitterEmail to lowercase for case-insensitive comparison:
    submitterEmail = submitterEmail.toLowerCase();

    // Check for duplicate emails using some():
    return users.some((user) => {
      const email = user.email?.toLowerCase(); // Handle missing email property gracefully
      return email === submitterEmail;
    });
  };

  const handleSubmit = async () => {
    users.forEach((user) => {
      user.email = user.email.toLowerCase();
    });

    //submitted form
    const formData = {
      application: {
        applicationId: applicationId,
        submitterEmail: currentUser.email,
        submittedInfo: {
          adminFirstName,
          adminLastName,
          orgName,
          orgAddress,
          businessLine,
          useDescription,
        },

        users,

        termsAndConditions: {
          hitgenAccepted: hitgenAccepted,
          xchemAccepted: xchemAccepted,
        },
      },

      admin: {
        email: currentUser.email,
        firstName: adminFirstName,
        lastName: adminLastName,
        role: "Admin",
      },
    };

    const checkSubmitterEmail = checkWithSubmitterEmail(
      formData.application.users,
      currentUser.email
    );
    if (checkSubmitterEmail) {
      setDialogVisible({
        visible: true,
        message: `${currentUser.email} is already a part of the application, please provide a different email address`,
        header: "Duplicate Email",
      });
      // alert("Please provide a different email address for each user");
      return;
    }

    const checkuserEmails = checkDuplicateEmails(
      formData.application.users,
      application.submitterEmail
    );
    if (checkuserEmails) {
      setDialogVisible({
        visible: true,
        message:
          "Please verify a different email address is used for each user",
        header: "Duplicate Email",
      });

      return;
    }
    try {
      const res = await axios.post("/api/application/update", formData);
      if (res.data !== "Records updated successfully") {
        setDialogVisible({
          visible: true,
          message: res.data,
          header: "Error",
        });
      } else {
        Swal.fire({
          title: "Success!",
          text: res.data,
          icon: "success",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          customClass: {
            confirmButton:
              "bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/datasets"; // Navigate to the home page
          }
        });
        // alert(res.data);
        // navigate("/datasets");
      }
    } catch (error) {
      console.error("Error during form submission:", error);
    }
  };

  const areFieldsEmpty = () => {
    // Check if general fields are empty
    if (
      !adminFirstName ||
      !adminLastName ||
      !(individualApplication || orgName) || // Only check orgName only if individualApplication is false
      !orgAddress ||
      !businessLine ||
      !useDescription
    ) {
      return true;
    }

    // Check if any user has empty details
    for (const user of users) {
      if (!user.firstName || !user.lastName || !user.email || !user.role) {
        return true;
      }
    }

    return false;
  };

  const handleUserChange = (index, field, value) => {
    const updatedUsers = [...users];

    updatedUsers[index] = {
      ...updatedUsers[index],
      [field]: value,
    };
    setApplicationUsers(updatedUsers);
  };

  const addUser = () => {
    setApplicationUsers((prevState) => [
      ...prevState,
      { firstName: "", lastName: "", email: "", role: "" },
    ]);
  };

  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const showConfirmDialog = (email, fname, lname, role, index) => {
    const nonEmptyField = fname || lname || role || email;
    setSelectedIndex(index);
    setSelectedEmail(email);
    if (nonEmptyField) {
      setVisibleConfirm(true);
    } else {
      setApplicationUsers(users.filter((_, i) => i !== index));
    }
  };

  const removeUser = async () => {
    setVisibleConfirm(false);
    setLoading(true);
    let index = selectedIndex;
    let email = selectedEmail;

    const updatedUsers = users.filter((user, i) => i !== index);

    const preUsers = application.users;

    if (application) {
      const body = {
        application,
        email: email,
      };

      try {
        if (email) {
          if (email === application.submitterEmail) {
            // alert("Please choose an email other than the submitter's email");
            setDialogVisible({
              visible: true,
              message:
                "Please choose an email other than the submitter's email",
              header: "Duplicate Email",
            });
            setApplicationUsers(preUsers);
            setLoading(false);
            return;
          }
          const response = await axios.put(
            "/api/application/update/revoke",
            body
          );

          if (
            response.data !==
            "Application and all associated users, including the Admin, deleted successfully."
          ) {
            // alert("Something went wrong, please try again later...");
            setDialogVisible({
              visible: true,
              message: "Something went wrong, please try again later...",
              header: "Error",
            });
            setApplicationUsers(preUsers);
            setLoading(false);
            return;
          }
        }

        setApplicationUsers(updatedUsers);

        setLoading(false);
      } catch (error) {
        setApplicationUsers(updatedUsers);
        setLoading(false);
        if (error.response && error.response.status === 404) {
          console.error("Resource not found:", error);
        } else {
          console.error("Error:", error);
        }
      }
    }
  };

  if (isLoading) {
    return (
      <div className="h-screen flex justify-content-center items-center">
        <ProgressSpinner
          style={{ width: "250px", height: "250px" }}
          strokeWidth="6"
        />
      </div>
    );
  }

  return application ? (
    <div className="shadow-md border-gray-200 border-2 rounded px-8 pt-6 pb-8 mb-4">
      {dialogVisible.visible && (
        <CustomDialog
          dialogVisible={dialogVisible.visible}
          setDialogVisible={setDialogVisible}
          message={dialogVisible.message}
          header={dialogVisible.header}
        />
      )}

      <div className="mb-4">
        <label
          htmlFor="adminFirstName"
          className="after:content-['*'] after:ml-1 after:text-red-500 block text-gray-700 font-bold mb-2"
        >
          {individualApplication
            ? "Your First Name"
            : "Administrator First Name"}
        </label>
        <InputText
          value={adminFirstName}
          readOnly={
            applicationId === "AdminPortal" ||
            !(currentUser?.email === application.submitterEmail)
          }
          onChange={(e) => setAdminFirstName(e.target.value)}
          id="adminFirstName"
          type="text"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label
          htmlFor="adminLastName"
          className="after:content-['*'] after:ml-1 after:text-red-500 block text-gray-700 font-bold mb-2"
        >
          {individualApplication ? "Your Last Name" : "Administrator Last Name"}
        </label>
        <InputText
          value={adminLastName}
          readOnly={
            applicationId === "AdminPortal" ||
            !(currentUser?.email === application.submitterEmail)
          }
          onChange={(e) => setAdminLastName(e.target.value)}
          // onChange={(e) => handleInputChange(e)}

          id="adminLastName"
          type="text"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      {!individualApplication && (
        <div className="mb-4">
          <label htmlFor="orgName" className="block text-gray-700 mb-2">
            <span className="font-bold after:content-['*'] after:ml-1 after:text-red-500">
              Legal Name of Proposed Corporation/Organization
            </span>
            <p className="text-xs text-gray-500">
              If you are proposing to obtain access to the Datasets for use by
              your organization, please provide the organization’s legal name,
              which will be the proposed “User” of the Datasets for purposes of
              the End User License Agreements below.
            </p>
          </label>
          <InputText
            value={orgName}
            // readOnly={
            //   applicationId === "AdminPortal" ||
            //   !(currentUser?.email === application.submitterEmail)
            // }
            readOnly
            onChange={(e) => setOrgName(e.target.value)}
            id="orgName"
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
      )}
      <div className="mb-4">
        <label htmlFor="orgAddress" className="block text-gray-700 mb-2">
          <span
            className={`font-bold ${
              applicationId !== "AdminPortal"
                ? "after:content-['*'] after:ml-1 after:text-red-500"
                : ""
            }`}
          >
            {individualApplication
              ? "Address"
              : "Address of Corporation/Organization"}
          </span>
          <p className="text-xs text-gray-500">
            {individualApplication
              ? "Please provide your address here"
              : "Please provide the legal address for the proposed “User”Corporation/Organization"}
          </p>
        </label>
        <InputText
          value={orgAddress}
          // readOnly={
          //   applicationId === "AdminPortal" ||
          //   !(currentUser?.email === application.submitterEmail)
          // }
          readOnly
          onChange={(e) => setOrgAddress(e.target.value)}
          id="orgAddress"
          type="text"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="businessLine" className="block text-gray-700 mb-2">
          <span className="font-bold after:content-['*'] after:ml-1 after:text-red-500">
            {individualApplication
              ? "Current Role"
              : "Corporation/Organization Line(s) of Business"}
          </span>
          <p className="text-xs text-gray-500">
            {individualApplication
              ? "provide a description of your current role(s) and qualifications "
              : null}
          </p>
        </label>
        <InputText
          value={businessLine}
          onChange={(e) => setBusinessLine(e.target.value)}
          readOnly={
            applicationId === "AdminPortal" ||
            !(
              currentUser?.email === application.submitterEmail ||
              application.submittedInfo?.organizationType === "Corporate"
            )
          }
          id="businessLine"
          type="text"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>

      <div className="mb-4">
        <label
          htmlFor="applicationSubmittedData"
          className="block text-gray-700 mb-2"
        >
          <span className="font-bold  after:ml-1 after:text-red-500">
            Application Submitted Date
          </span>
        </label>
        <InputText
          value={newPaddedDate}
          readOnly
          id="applicationSubmittedData"
          type="text"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      {applicationId === "AdminPortal" && (
        <div className="mb-4">
          <label
            htmlFor="applicationSubmittedData"
            className="block text-gray-700 mb-2"
          >
            <span className="font-bold  after:ml-1 after:text-red-500">
              Admin Action Details
            </span>
          </label>
          <InputText
            value={decisionMessage}
            readOnly
            id="applicationSubmittedData"
            type="text"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
      )}

      {!application.individualApplication && (
        <div className="shadow-md rounded px-8 pt-6 pb-8 mb-4 border-gray-100 border-2">
          <div className="mb-4">
            <label className="block text-gray-700 mb-2">
              <span className="font-bold after:content-['*'] after:ml-1 after:text-red-500">
                “User” Corporation/Organization Employees Requiring Access
              </span>

              <p className="text-xs text-gray-500">
                Please provide the names, email addresses, and titles/roles for
                the employees in your organization requiring access. Once
                approved, those individuals will be able to view and download
                the Datasets by logging in to AIRCHECK. If you are the proposed
                “User” as an individual person, do not complete this section as
                only you will be provided access if approved.
              </p>
            </label>
            {users &&
              users.map((user, index) => (
                <div key={index} className="md:grid md:grid-cols-5 md:gap-3">
                  <div className="relative z-0 group">
                    <InputText
                      value={user.firstName}
                      type="text"
                      readOnly={
                        applicationId === "AdminPortal" ||
                        !(currentUser?.email === application.submitterEmail)
                      }
                      placeholder=" "
                      onChange={(e) =>
                        handleUserChange(index, "firstName", e.target.value)
                      }
                      id={`floating_fname_${index}`}
                      className="after:content-['*'] after:ml-1 after:text-red-500 block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    />
                    <label
                      htmlFor={`floating_fname_${index}`}
                      className="after:content-['*'] after:ml-1 after:text-red-500 peer-focus: absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      First Name
                    </label>
                  </div>

                  <div className="relative z-0 group">
                    <InputText
                      value={user.lastName}
                      readOnly={
                        applicationId === "AdminPortal" ||
                        !(currentUser?.email === application.submitterEmail)
                      }
                      type="text"
                      onChange={(e) =>
                        handleUserChange(index, "lastName", e.target.value)
                      }
                      placeholder=""
                      className="after:content-['*'] after:ml-1 after:text-red-500 block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    />
                    <label
                      htmlFor={`floating_fname_${index}`}
                      className="after:content-['*'] after:ml-1 after:text-red-500 peer-focus: absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Last Name
                    </label>
                  </div>
                  <div className="relative z-0 group">
                    {application.users.some(
                      (existingUser) => existingUser.email === user.email
                    ) && user.email !== "" ? (
                      <>
                        <p className="after:content-['*'] after:ml-1 after:text-red-500 block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none">
                          {user.email}
                        </p>
                      </>
                    ) : (
                      // User is not in the application, render editable input field
                      <InputText
                        value={user.email}
                        readOnly={
                          applicationId === "AdminPortal" ||
                          !(currentUser?.email === application.submitterEmail)
                        }
                        type="email"
                        placeholder=""
                        onChange={(e) =>
                          handleUserChange(index, "email", e.target.value)
                        }
                        className="after:content-['*'] after:ml-1 after:text-red-500 block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      />
                    )}

                    <label
                      htmlFor={`floating_fname_${index}`}
                      typeof="email"
                      className="after:content-['*'] after:ml-1 after:text-red-500 peer-focus: absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Email
                    </label>
                    <p className="invisible peer-invalid:visible text-pink-400 text-sm">
                      Please provide a valid email address.
                    </p>
                  </div>
                  <div className="relative z-0 group">
                    <InputText
                      value={user.role}
                      readOnly={
                        applicationId === "AdminPortal" ||
                        !(currentUser?.email === application.submitterEmail)
                      }
                      type="text"
                      onChange={(e) =>
                        handleUserChange(index, "role", e.target.value)
                      }
                      placeholder=""
                      className="after:content-['*'] after:ml-1 after:text-red-500 block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    />
                    <label
                      htmlFor={`floating_fname_${index}`}
                      className="after:content-['*'] after:ml-1 after:text-red-500 peer-focus: absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                    >
                      Role
                    </label>
                  </div>

                  <Button
                    className="text-red-600 w-8"
                    disabled={
                      !(currentUser?.email === application.submitterEmail) ||
                      applicationId === "AdminPortal"
                    }
                    // onClick={() => removeUser(index, user.email)}
                    onClick={() =>
                      showConfirmDialog(
                        user.email,
                        user.firstName,
                        user.lastName,
                        user.role,
                        index
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M22 10.5h-6m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM4 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 10.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
                      />
                    </svg>
                  </Button>

                  <Dialog
                    visible={visibleConfirm}
                    header="Delete Confirmation"
                    style={{ width: "500px", textAlign: "center" }}
                    onHide={() => setVisibleConfirm(false)}
                    footer={
                      <div>
                        <Button
                          label="No"
                          onClick={() => setVisibleConfirm(false)}
                          className="w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-red-500 text-base text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
                        />
                        <Button
                          className="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-green-500 text-base text-white hover:bg-green-700 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          label="Yes"
                          onClick={() => removeUser()}
                        />
                      </div>
                    }
                  >
                    <p>
                      Are you sure you want to delete {selectedEmail}? This
                      action cannot be undone. Please confirm your decision
                    </p>
                  </Dialog>
                </div>
              ))}
            <Button
              className="text-green-600 w-6"
              disabled={
                !(currentUser?.email === application.submitterEmail) ||
                applicationId === "AdminPortal"
              }
              onClick={addUser}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
                />
              </svg>
            </Button>
          </div>
        </div>
      )}
      <div className="mb-4">
        <label htmlFor="useDescription" className="block text-gray-700 mb-2">
          <span className="font-bold after:content-['*'] after:ml-1 after:text-red-500">
            “Proposed Use” of Datasets
          </span>
          <p className="text-xs text-gray-500">
            Please provide a brief, non-confidential, high-level description of
            the machine learning algorithm(s) or other research approach(es)
            that the User proposes to employ in analyzing the Datasets in
            sufficient detail to enable a person skilled in the relevant field
            to readily understand the User’s proposed use(s).
          </p>
        </label>
        <InputTextarea
          readOnly={
            applicationId === "AdminPortal" ||
            !(currentUser?.email === application.submitterEmail)
          }
          value={useDescription}
          onChange={(e) => setUseDescription(e.target.value)}
          id="useDescription"
          type="text"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        />
      </div>
      {applicationId === "AdminPortal" || rejectionMsg ? (
        <div className="mb-4">
          <>
            <label
              htmlFor="rejectionReason"
              className="block text-gray-700 mb-2"
            >
              <span className="font-bold  after:ml-1 after:text-red-500">
                Rejection Explanation
              </span>
            </label>
            <InputTextarea
              readOnly={applicationId !== "AdminPortal"}
              id="rejectionReason"
              placeholder="Please explain the reason for rejection and provide guidance for resubmission."
              value={rejectionMsg || rejectJustification}
              onChange={handleInputChange}
              type="text"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            />
          </>
        </div>
      ) : null}

      <div className="flex items-center mt-2">
        {adminInformation && adminInformation.hitgenAdmin ? null : (
          <>
            {" "}
            {application.termsAndConditions.xchemAccepted ? (
              <>
                <img
                  src="/images/icons/check.svg"
                  className="w-6"
                  alt="check-mark"
                />
                <span className="mx-3">
                  X-Chem end user license agreement accepted
                </span>
              </>
            ) : (
              <div className="flex flex-row justify-center">
                <div className="xchem-tooltip">
                  <input
                    type="checkbox"
                    onChange={() => setXchemAccepted(!xchemAccepted)}
                    checked={xchemAccepted}
                    // disabled={!openedEULAXchem}
                    disabled={
                      !(currentUser?.email === application.submitterEmail) ||
                      applicationId === "AdminPortal" ||
                      !openedEULAXchem
                    }
                  />
                  {!openedEULAXchem && (
                    <Tooltip
                      target=".xchem-tooltip"
                      content="Review X-Chem EULA before accepting"
                      position="bottom"
                    />
                  )}
                </div>
                <div className="ml-3">
                  <label className="text-gray-700 text-sm">
                    <p>
                      By clicking here, you hereby acknowledge having read and
                      understood the{" "}
                      <span onClick={() => setOpenedEULAXchem(true)}>
                        <XchemPDFLink />
                      </span>{" "}
                      and you hereby agree on behalf of the User that, if your
                      registration is accepted, the User shall be bound by all
                      of the terms and conditions thereof.
                    </p>
                  </label>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className="flex items-center mt-2">
        {adminInformation && adminInformation.xchemAdmin ? null : (
          <>
            {application.termsAndConditions.hitgenAccepted ? (
              <>
                <img
                  src="/images/icons/check.svg"
                  className="w-6"
                  alt="check-mark"
                />
                <span className="mx-3">
                  HitGen end user license agreement accepted
                </span>
              </>
            ) : (
              <div className="flex flex-row justify-center">
                <div className="hitgen-tooltip">
                  <input
                    type="checkbox"
                    onChange={() => setHitgenAccepted(!hitgenAccepted)}
                    checked={hitgenAccepted}
                    // disabled={!openedEULAHitgen}
                    disabled={
                      !(currentUser?.email === application.submitterEmail) ||
                      applicationId === "AdminPortal" ||
                      !openedEULAHitgen
                    }
                  />
                </div>
                {!openedEULAHitgen && (
                  <Tooltip
                    target=".hitgen-tooltip"
                    content="Review HitGen EULA before accepting"
                    position="bottom"
                  />
                )}

                <div className="ml-3">
                  <label className="text-gray-700 text-sm">
                    <p>
                      By clicking here, you hereby acknowledge having read and
                      understood the{" "}
                      <span onClick={() => setOpenedEULAHitgen(true)}>
                        <HitgenPDFLink />
                      </span>{" "}
                      and you hereby agree on behalf of the User that, if your
                      registration is accepted, the User shall be bound by all
                      of the terms and conditions thereof.
                    </p>
                  </label>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <div className="flex items-center">
        {adminInformation && adminInformation.hitgenAdmin ? null : (
          <div className="flex items-center mt-2">
            {application.termsAndConditions.xchemAccepted ? (
              application.decision.xchemAccepted === null ? (
                <img
                  src="/images/icons/pending.gif"
                  className="w-6"
                  alt="pending-mark"
                />
              ) : application.decision.xchemAccepted ? (
                <img
                  src="/images/icons/check.svg"
                  className="w-6"
                  alt="check-mark"
                />
              ) : (
                <img
                  src="/images/icons/remove.svg"
                  className="w-6"
                  alt="remove-mark"
                />
              )
            ) : (
              <img
                src="/images/icons/na.svg"
                className="w-6"
                alt="remove-mark"
              />
            )}

            <span className="ml-3 mr-3">
              {application.termsAndConditions.xchemAccepted
                ? application.decision.xchemAccepted === null
                  ? "X-Chem application Pending for approval"
                  : application.decision.xchemAccepted
                  ? "X-Chem application Accepted"
                  : "X-Chem application  Rejected"
                : "This application is not applicable for X-chem."}
            </span>
          </div>
        )}

        {adminInformation && adminInformation.xchemAdmin ? null : (
          <div className="flex items-center  mt-2">
            {application.termsAndConditions.hitgenAccepted ? (
              application.decision.hitgenAccepted === null ? (
                <img
                  src="/images/icons/pending.gif"
                  className="w-6"
                  alt="pending-mark"
                />
              ) : application.decision.hitgenAccepted ? (
                <img
                  src="/images/icons/check.svg"
                  className="w-6"
                  alt="check-mark"
                />
              ) : (
                <img
                  src="/images/icons/remove.svg"
                  className="w-6"
                  alt="remove-mark"
                />
              )
            ) : (
              <img
                src="/images/icons/na.svg"
                className="w-6"
                alt="remove-mark"
              />
            )}

            <span className="ml-3">
              {application.termsAndConditions.hitgenAccepted
                ? application.decision.hitgenAccepted === null
                  ? "HitGen application Pending for approval"
                  : application.decision.hitgenAccepted
                  ? "HitGen application Accepted"
                  : "HitGen application  Rejected"
                : "This application is not applicable for X-chem."}
            </span>
          </div>
        )}
      </div>
      <div className="mt-8 flex items-center justify-center">
        <div className="tooltip-wrapper">
          {currentUser?.email === application.submitterEmail &&
          applicationId !== "AdminPortal" ? (
            <Button
              className="text-lg md:text-xl flex justify-center  bg-gradient-to-r from-[#e53201] to-[#f36548] text-white px-6 py-2 rounded-3xl  hover:bg-[#a2d2ff] transition-colors"
              disabled={
                areFieldsEmpty() ||
                !currentUser ||
                !(currentUser?.email === application.submitterEmail) ||
                applicationId === "AdminPortal"
              }
              onClick={handleSubmit}
            >
              Update
            </Button>
          ) : null}

          {(areFieldsEmpty() || !currentUser) && (
            <Tooltip
              target=".tooltip-wrapper"
              content="Verify you've completed all fields and you're logged in"
              position="top"
            />
          )}
        </div>
      </div>
    </div>
  ) : (
    <span>Loading . . .</span>
  );
};

export default ApplicationInformation;
