import React from "react";

const Footer = () => {
  return (
    <footer className="bg-[#06002e] relative  text-[#3a3380] bottom-0 z-10 w-full pt-3 pb-4 px-8 md:px-5  overflow-hidden">
      <div className="container mx-auto md:pl-20  md:py-8 flex flex-col md:flex-row items-start justify-between">
        {/* Mobile Navigation - Only visible on small screens */}
        <div className="md:hidden w-full mb-8">
          {/* <div className="bg-[#4d4d4d] rounded-lg p-4 mx-auto max-w-sm"> */}
          <div className="bg-[#4d4d4d] rounded-lg   mx-auto max-w-sm relative mt-8">
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full h-20 w-20 flex items-center justify-center  border-8 border-[#4d4d4d]">
              <img src="/images/new-logos/orange.png" alt="AIRCHECK Logo" className="h-10 w-auto object-contain" />
            </div>

            <div className="grid grid-cols-4 gap-4 text-center pl-2">
              <button
                className="text-white rounded-md text-xs font-medium p"
                onClick={() => (window.location.href = "/")}
              >
                <div className="flex flex-col items-center pt-2">
                  <img src="/images/icons/home.png" alt="Home" className="h-8 w-8 mb-1" />
                  <span className="text-xs">Home</span>
                </div>
              </button>
              <button
                className="text-white  rounded-md text-xs font-medium"
                onClick={() => (window.location.href = "/models")}
              >
                <div className="flex flex-col items-center pt-2 pr-6">
                  <img src="/images/icons/pipeline.png" alt="Home" className="h-10 w-10 mb-1" />
                  <span className="text-xs">Pipeline</span>
                </div>
              </button>
              <button
                className="text-white py-2 px-1 rounded-md text-xs font-medium"
                onClick={() => (window.location.href = "/datasets")}
              >
                <div className="flex flex-col items-center pl-4 pt-2">
                  <img src="/images/icons/download.svg" alt="Home" className="h-8 w-auto mb-1" />
                  <span className="text-xs">Datasets</span>
                </div>
              </button>
              <button
                className="text-white py-2 px-1 rounded-md text-xs font-medium"
                onClick={() => (window.location.href = "/profile")}
              >
                <div className="flex flex-col items-center pt-2">
                  <img src="/images/icons/profile.png" alt="Home" className="h-8 w-auto mb-1" />
                  <span className="text-xs">Profile</span>
                </div>
              </button>
              {/* <button className="bg-[#1e1a5a] text-white py-2 px-1 rounded-md text-xs font-medium">Profile</button> */}
            </div>
          </div>
        </div>

        {/* Left side content - 2/3 width (hidden on mobile) */}
        <div className="w-full md:w-2/3 flex flex-col md:flex-row items-center md:items-start gap-2">
          <div className="flex flex-col md:mr-8 items-center md:items-start text-left md:text-left">
            {/* Desktop logo - hidden on mobile */}
            <img
              src="/images/new-logos/new_aircheck_footer_logo.png"
              alt="AIRCHECK Logo"
              className="max-h-12 min-w-[120px] w-auto object-contain hidden md:block"
            />
            <p className="text-sm text-white leading-relaxed">
              AIRCHECK is developed and populated as a collaboration between the Structural Genomics Consortium,
              University Health Network, University of Toronto, University of North Carolina, X-Chem, and HitGen.
            </p>
          </div>
        </div>

        {/* Right side links - 1/3 width (hidden on mobile) */}
        <div className="w-full md:w-1/3 flex flex-col items-center mr-16 mt-8 md:mt-0 text-center md:text-center hidden md:flex">
          <h3 className="text-xl md:text-xl font-extralight text-white mb-2">
            <a href="/feedbackform" className="text-white hover:text-blue-200 transition duration-300">
              We Value Your Feedback
            </a>
          </h3>
          <p className="text-sm text-white">Help us improve our platform by sharing your thoughts and experience.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
