import React from "react";

const AircheckStacks = () => {
  const details = [
    {
      Id: 1,
      src: "/images/icons/stacks/1.png",
      description: "AI-ready datasets for drug discovery.",
    },
    {
      Id: 2,
      src: "/images/icons/stacks/2.png",
      description: "Transparent and reproducible predictive models.",
    },
    {
      Id: 3,
      src: "/images/icons/stacks/3.png",
      description: "Tools to test AI models against real experimental data.",
    },
    {
      Id: 4,
      src: "/images/icons/stacks/4.png",
      description: "Documents for connecting datasets to AI models.",
    },
    {
      Id: 5,
      src: "/images/icons/stacks/5.png",
      description: "Resources for experimenting with data and models.",
    },
    {
      Id: 6,
      src: "/images/icons/stacks/6.png",
      description: "Training Materials",
    },
  ];

  return (
    <div className="w-full bg-[#06002e] shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1),0_-2px_4px_-1px_rgba(0,0,0,0.06)] my-20  md:py-24">
      <div className="container py-16 mx-auto flex flex-col items-start justify-between">
        {/* Title section */}

        <p className="text-[18pt] text-center  md:text-[56pt] font-bold  text-white mb-8 pl-8 pr-8 md:pl-16">
          AIRCHECK's cloud-based platform offers
        </p>

        {/* Grid section */}

        <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 md:place-items-center md:gap-x-8 md:gap-x-12 lg:gap-x-16 md:gap-y-10 md:pl-12 md:pr-10">
          {details.map((item) => (
            <div
              key={item.Id}
              // className="flex flex-col md:items-center md:justify-center px-8 py-4 md:p-6 rounded-lg md:shadow-[inset_0_0_10px_0_rgba(255,255,255,0.2)]  transition-shadow"
              className="flex flex-col md:items-center md:justify-center px-8 py-4 md:p-6 rounded-lg  transition-shadow"
            >
              <div className="md:text-center">
                <p className="text-[12pt] md:text-[24pt] lg:text-[24pt] text-white font-extralight">
                  {item.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AircheckStacks;
