import React from "react";

const DataTypes = () => {
  const details = [
    {
      Id: 1,
      title: "EAS-MS Data",
      src: "/images/icons/dbsets/data-virtualization.svg",
      description:
        "High-precision screening of small molecule-protein interactions.",
    },
    {
      Id: 2,
      title: "DEL Screening Data",
      src: "/images/icons/dbsets/del.png",
      description: "Large-scale datasets covering billions of molecules.",
    },
    {
      Id: 3,
      title: "Hit Validation Data",
      src: "/images/icons/dbsets/validated.svg",
      description:
        "Experimentally confirmed binding results using industry-standard methods.",
    },
    {
      Id: 4,
      title: "Protein Target Data",
      src: "/images/icons/dbsets/protein.svg",
      description:
        "Detailed biochemical and structural data on disease-related proteins.",
    },
  ];

  return (
    <>
      <div className="w-full ">
        <div className="container mx-auto px-8 md:px-0 md:pr-6 md:pl-20  md:py-24 flex flex-col md:flex-row items-start justify-between">
          {/* Left Section */}
          <div className="w-full md:w-1/3 flex flex-col">
            <p className="text-[18pt] md:text-[44pt]  lg:text-[56pt] text-center md:text-left font-bold text-black md:leading-[1.2]">
              What’s in AIRCHECK
            </p>
            <p className="text-black text-[12pt] py-4 text-center md:text-left md:text-[32pt] pr-8 font-extralight ">
              AI-ready datasets designed to train reliable machine learning
              models for drug discovery.
            </p>
          </div>

          {/* Right Section (Grid Items) */}
          <div className="w-full md:w-2/3 flex flex-col md:my-4  md:pr-12 md:px-16">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {details.map((item) => (
                <div
                  key={item.Id}
                  className="bg-[#06002e] px-2 md:pb-4 md:px-4 flex flex-col  rounded-lg shadow-md shadow-lg  transition-shadow duration-300"
                >
                  {/* Title */}
                  <div className="w-full text-left">
                    <p className="text-[16pt] md:text-[26pt] text-white font-bold">
                      {item.title}
                    </p>
                  </div>

                  {/* Description */}
                  <div className="w-full text-left ">
                    <p className="text-[12pt] md:text-[18pt] text-white font-extralight">
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataTypes;
