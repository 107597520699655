import React from "react";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const CompoundTable = ({ predictedResult }) => {
  if (!predictedResult) {
    throw new Error("Invalid predicted result structure");
  }
  const smiles_name = predictedResult.smiles_name || [];
  const predictions = predictedResult.result?.data?.predictions || [];

  const data = smiles_name.map((smiles, index) => ({
    smiles,
    prediction:
      predictions[index] != null
        ? Number(predictions[index]).toFixed(4)
        : "Not a Valid Compound",
    isValid: predictions[index] != null,
  }));

  return (
    <div className="p-4">
      <h1 className="text-xl font-bold mb-4">{predictedResult.message}</h1>
      <Card>
        <DataTable value={data} rows={10} tableStyle={{ width: "auto" }}>
          <Column
            field="smiles"
            header="SMILE/COMPOUND"
            body={(rowData) => (
              <span className={rowData.isValid ? "" : "text-red-500"}>
                {rowData.smiles}
              </span>
            )}
          />
          <Column
            field="prediction"
            header="Prediction"
            headerTooltip="Prediction based on Molecular Fingerprints"
            body={(rowData) => (
              <span className={rowData.isValid ? "" : "text-red-500"}>
                {rowData.prediction}
              </span>
            )}
          />
        </DataTable>
      </Card>
    </div>
  );
};

export default CompoundTable;
