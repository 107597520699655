import { Link as ScrollLink } from "react-scroll";
import { useNavigate } from "react-router-dom";
import React from "react";

const Dropdown = ({ setIsMobileDropdownOpen, doc_links, isMobile, setIsOpen, handleResourcesClick }) => {
  const navigate = useNavigate();

  const handleClick_here = () => {
    setIsMobileDropdownOpen(false);
    setIsOpen(false); // Close the navbar
    navigate("/readme"); // Navigate to /readme
  };

  return (
    <li className="relative group">
      <div className="relative">
        {isMobile ? (
          // On mobile, clicking "Resources" opens the MobileDropdownMenu
          <button
            onClick={() => setIsMobileDropdownOpen(true)}
            className="flex items-center text-white hover:text-[#e6cb64] transition duration-200 px-2"
          >
            <span>Resources</span>
            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path
                fillRule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        ) : (
          // On desktop, show the dropdown on hover
          <>
            <button
              className="flex items-center text-white hover:text-[#e6cb64] transition duration-200 px-2"
              onClick={() => handleClick_here()}
            >
              <span>Resources</span>
              <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <ul className="absolute left-0 top-full w-56 bg-[#06002e] shadow-lg rounded-b-md hidden group-hover:block">
              {Object.entries(doc_links).map(([label, href_link]) => (
                <li key={label}>
                  <ScrollLink
                    key={label}
                    to={label}
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                    onClick={() => handleClick_here()}
                    className="block text-white  hover:text-[#e6cb64] transition duration-300 px-4 py-2 text-sm"
                  >
                    {label}
                  </ScrollLink>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </li>
  );
};

export default Dropdown;
