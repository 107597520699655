import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = ({ error }) => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 text-center p-4">
      <h1 className="text-4xl font-bold text-red-600 mb-4">Oops! Something Went Wrong</h1>
      <p className="text-xl text-gray-700 mb-6">We encountered an unexpected error. Please try again later.</p>
      {error && (
        <div className="bg-red-50 p-4 rounded-md mb-6">
          {/* <p className="text-red-800">Error Details: {error.toString()}</p> */}
        </div>
      )}
      <div className="flex space-x-4">
        <button
          className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600 transition"
          onClick={() => (window.location.href = "/datasets")} // Navigate to the home page
        >
          Go to Home
        </button>
        <button
          onClick={() => window.location.reload()}
          className="bg-green-500 text-white px-6 py-2 rounded hover:bg-green-600 transition"
        >
          Reload Page
        </button>
      </div>
    </div>
  );
};

export default ErrorPage;
