import { DataTable } from "primereact/datatable";

import { Column } from "primereact/column";
import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";

import { DATASOURCE_DATA } from "../../utils/constants/mapping_dict";
import ModelDetailsPopup from "./ModelMetrics";

const TopBar = ({ title }) => {
  return (
    <div className="flex flex-row mx-auto w-full my-8 justify-center">
      <h1 className="text-gray-800 font-bold text-2xl md:text-3xl">{title}</h1>
    </div>
  );
};

// const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const fetchModelDetails = async (source) => {
  try {
    const res = await axios.get(`/api/model_serving/mlmodel_stats/${source}`);
    return res.data;
  } catch (error) {
    console.error("Error fetching model details:", error);
    return [];
  }
};

const DisplayModels = () => {
  const navigate = useNavigate();
  const [selectedDataSource, setSelectedDataSource] = useState("");

  const [selectedTarget, setSelectedTarget] = useState("");

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedRunId, setSelectedRunId] = useState(null);
  const [selectedTargetName, setSelectedTargetName] = useState("");
  const [selectedModel, setSelectedModel] = React.useState("");

  const [isDropdownLoading, setIsDropdownLoading] = useState(false);
  const {
    data: allModels = [],
    isLoading: isLoadingAll,
    error: allError,
  } = useQuery({
    queryKey: ["modelDetails", "ALL"],
    queryFn: () => fetchModelDetails("ALL"),
    staleTime: 5 * 60 * 1000,
  });

  const {
    data: selectedData = [],
    isLoading: isLoadingSelected,
    error: selectedError,
    isFetching,
  } = useQuery({
    queryKey: ["modelDetails", selectedDataSource],
    queryFn: () => fetchModelDetails(selectedDataSource),
    enabled: !!selectedDataSource,
    staleTime: 5 * 60 * 1000,
  });

  const availableTargets = React.useMemo(() => {
    if (!Array.isArray(selectedData) || selectedData.length === 0) return [];
    const targetMap = new Map();
    selectedData.forEach(({ Target_name, Model_name }) => {
      if (!Target_name) return;
      if (!targetMap.has(Target_name)) {
        targetMap.set(Target_name, {
          id: Target_name,
          name: Target_name,
          models: new Set(),
        });
      }
      targetMap.get(Target_name).models.add(Model_name);
    });
    return Array.from(targetMap.values()).map((target) => ({
      ...target,
      models: [...target.models], // Convert Set to Array
    }));
  }, [selectedData]);

  const availableModels = React.useMemo(() => {
    if (!selectedTarget) {
      const allModelsForDataSource = selectedData.map(
        (model) => model.Model_name
      );
      return [...new Set(allModelsForDataSource)];
    }
    const target = availableTargets.find((t) => t.id === selectedTarget);
    return target ? target.models : [];
  }, [selectedTarget, availableTargets, selectedData]);

  const handleDataSourceChange = (e) => {
    setIsDropdownLoading(true);
    setSelectedDataSource(e.target.value);
    setSelectedTarget("");
    setSelectedModel("");
  };

  const handleTargetChange = (e) => {
    setIsDropdownLoading(true);
    setSelectedTarget(e.target.value);
    setSelectedModel("");

    setTimeout(() => setIsDropdownLoading(false), 3000);
  };

  const handleModelChange = (e) => {
    setIsDropdownLoading(true);
    setSelectedModel(e.target.value);

    setTimeout(() => setIsDropdownLoading(false), 3000);
  };

  useEffect(() => {
    if (!isLoadingSelected && !isFetching && isDropdownLoading) {
      setIsDropdownLoading(false);
    }
  }, [isLoadingSelected, isFetching, isDropdownLoading]);

  const renderLink = (rowData) => {
    return (
      <a href={rowData.Code_link} target="_blank" rel="noopener noreferrer">
        View Model
      </a>
    );
  };

  const renderModelStats = (rowData) => {
    return (
      <Button
        icon="pi pi-external-link"
        tooltip="View Model Performance" // This will show the name on hover
        tooltipOptions={{ position: "top" }}
        className="p-button-rounded p-button-info"
        onClick={() => {
          setSelectedRunId(rowData.Run_id);
          setSelectedTargetName(rowData.Name);
          setIsPopupVisible(true);
        }}
      />
    );
  };
  const renderCompoundPrediction = (rowData) => {
    return (
      <Button
        // label="Predict your compound"
        icon="pi pi-chart-line"
        tooltip={`Predict your compound using ${rowData.Model_name} model`}
        tooltipOptions={{ position: "top" }}
        className="p-button-rounded p-button-info"
        onClick={() => {
          navigate(`/screening/${rowData.Run_id}/${rowData.Name}`);
        }}
      />
    );
  };

  // const renderModelCard = (rowData) => {
  //   return (
  //     <Button
  //       // label="View Model Card"
  //       icon="pi pi-credit-card mr-2"
  //       tooltip={`View Model card for ${rowData.Model_name} model`}
  //       tooltipOptions={{ position: "top" }}
  //       className="p-button-rounded p-button-info"
  //       onClick={() => {
  //         navigate(`/modelcard/${rowData.Run_id}/${rowData.Name}`);
  //       }}
  //     />
  //   );
  // };

  const filteredModels = React.useMemo(() => {
    let models = selectedDataSource ? selectedData : allModels;
    if (!Array.isArray(models)) models = [];

    if (selectedTarget) {
      models = models.filter((model) => model.Target_name === selectedTarget);
    }
    if (selectedModel) {
      models = models.filter((model) => model.Model_name === selectedModel);
    }

    return models;
  }, [
    selectedDataSource,
    selectedTarget,
    selectedModel,
    selectedData,
    allModels,
  ]);

  // Show error state if either query fails
  if (allError || selectedError) {
    return (
      <div className="flex justify-center items-center h-64">
        Error loading data: {allError?.message || selectedError?.message}
      </div>
    );
  }
  const isAnyLoading = isLoadingAll || isLoadingSelected || isDropdownLoading;
  // px-6 sm:px-20 lg:px-32 py-16 md:py-8 max-w-screen-xxl mx-auto
  return (
    <>
      {/* <div className="w-full bg-white py-36 md:my-12 md:py-12">*/}
      <div className="container h-screen mx-auto pl-8 pr-4 md:pl-20 md:pr-16">
        <div className="flex flex-col md:flex-row py-40  md:py-32 rounded-xl overflow-hidden shadow-2xl bg-white/80 backdrop-blur-sm">
          {/* Sidebar */}
          <div className="w-full md:w-80 md:flex-shrink-0 bg-white/90  px-6">
            <TopBar title="Choose Model" />

            <div className="space-y-6 mt-6">
              {/* Data Source Selector */}
              <div className="space-y-3">
                <label className="block text-sm font-medium text-gray-700">
                  Data Source
                </label>
                <div className="relative">
                  <select
                    value={selectedDataSource}
                    // onChange={(e) => setSelectedDataSource(e.target.value)}
                    onChange={handleDataSourceChange}
                    className="w-full p-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 appearance-none bg-white shadow-sm transition-all duration-200"
                  >
                    <option value="">Select Data Source</option>
                    {DATASOURCE_DATA.data.map((source) => (
                      <option key={source.id} value={source.id}>
                        {source.name}
                      </option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 text-gray-500">
                    <svg
                      className="h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              {/* Target Selector */}
              <div className="space-y-3">
                <label className="block text-sm font-medium text-gray-700">
                  Select Target
                </label>
                <div className="relative">
                  <select
                    value={selectedTarget}
                    // onChange={(e) => setSelectedTarget(e.target.value)}
                    onChange={handleTargetChange}
                    disabled={!selectedDataSource}
                    className="w-full p-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 appearance-none bg-white shadow-sm transition-all duration-200 disabled:bg-gray-50 disabled:text-gray-400 disabled:cursor-not-allowed"
                  >
                    <option value="">Select Target</option>
                    {availableTargets.map((target) => (
                      <option key={target.id} value={target.id}>
                        {target.name}
                      </option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 text-gray-500">
                    <svg
                      className="h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              {/* Model Selector */}
              <div className="space-y-3">
                <label className="block text-sm font-medium text-gray-700">
                  Select Model
                </label>
                <div className="relative">
                  <select
                    value={selectedModel}
                    // onChange={(e) => setSelectedModel(e.target.value)}
                    onChange={handleModelChange}
                    disabled={!selectedTarget}
                    className="w-full p-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 appearance-none bg-white shadow-sm transition-all duration-200 disabled:bg-gray-50 disabled:text-gray-400 disabled:cursor-not-allowed"
                  >
                    <option value="">Select Model</option>
                    {availableModels.map((model, index) => (
                      <option key={index} value={model}>
                        {model}
                      </option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 text-gray-500">
                    <svg
                      className="h-5 w-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Main Content Area */}
          <div className="w-full bg-white/90 p-4 md:p-6 flex-1 min-w-0">
            {/* {isLoadingAll || isLoadingSelected ? ( */}
            {isAnyLoading ? (
              <div className="fixed inset-0 flex justify-center items-center bg-black/60 backdrop-blur-sm z-50">
                <div className="bg-white/10 p-8 rounded-3xl shadow-2xl backdrop-blur-md">
                  <ProgressSpinner
                    style={{ width: "150px", height: "150px" }}
                    strokeWidth="4"
                    animationDuration=".8s"
                    fill="none"
                    strokecolor="rgb(79, 70, 229)"
                  />
                </div>
              </div>
            ) : (
              <div className="h-full flex flex-col">
                <div className="w-full overflow-hidden rounded-xl shadow-md flex-1 min-w-0">
                  <div className="overflow-x-auto h-full">
                    <DataTable
                      value={filteredModels}
                      paginator={filteredModels.length > 20}
                      rows={20}
                      rowsPerPageOptions={[20, 50, 100]}
                      tableStyle={{ minWidth: "100%" }} // Already responsive
                      paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                      currentPageReportTemplate="{first} to {last} of {totalRecords}"
                      emptyMessage="We're preparing to launch more models on the platform. Stay tuned!"
                      className="custom-data-table"
                      rowClassName="hover:bg-indigo-50 transition-colors duration-150"
                      scrollable
                    >
                      <Column
                        field="Model_name"
                        header="Model Name"
                        alignHeader="center"
                        style={{ minWidth: "7.5rem" }} // Changed from 120px to 7.5rem
                        headerClassName="bg-indigo-50 text-indigo-700 uppercase text-xs tracking-wider"
                      />
                      <Column
                        field="Name"
                        header="Target Name"
                        alignHeader="center"
                        style={{ minWidth: "7.5rem" }} // Changed from 120px to 7.5rem
                        headerClassName="bg-indigo-50 text-indigo-700 uppercase text-xs tracking-wider"
                      />
                      <Column
                        field="Description"
                        header="Description"
                        alignHeader="center"
                        style={{ minWidth: "9.375rem" }} // Changed from 150px to 9.375rem
                        headerClassName="bg-indigo-50 text-indigo-700 uppercase text-xs tracking-wider"
                      />
                      <Column
                        field="Run_id"
                        header="Experiment Results"
                        alignHeader="center"
                        body={renderModelStats}
                        style={{ minWidth: "6.25rem" }} // Changed from 100px to 6.25rem
                        headerClassName="bg-indigo-50 text-indigo-700 uppercase text-xs tracking-wider"
                      />
                      <Column
                        field="Run_id"
                        header="Prediction"
                        alignHeader="center"
                        body={renderCompoundPrediction}
                        style={{ minWidth: "6.25rem" }} // Changed from 100px to 6.25rem
                        headerClassName="bg-indigo-50 text-indigo-700 uppercase text-xs tracking-wider"
                      />
                      <Column
                        field="Code_link"
                        header="Code Repo"
                        body={renderLink}
                        alignHeader="center"
                        style={{ minWidth: "6.25rem" }} // Changed from 100px to 6.25rem
                        headerClassName="bg-indigo-50 text-indigo-700 uppercase text-xs tracking-wider"
                      />
                      {/* <Column
                      field="Run_id"
                      header="Model Card"
                      alignHeader="center"
                      body={renderModelCard}
                      style={{ minWidth: "6.25rem" }} // Changed from 100px to 6.25rem
                      headerClassName="bg-indigo-50 text-indigo-700 uppercase text-xs tracking-wider"
                    /> */}
                    </DataTable>
                  </div>
                </div>
              </div>
            )}

            <ModelDetailsPopup
              visible={isPopupVisible}
              onHide={() => {
                setIsPopupVisible(false);
                setSelectedRunId(null);
              }}
              runId={selectedRunId}
              targetName={selectedTargetName}
              className="backdrop-blur-lg"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DisplayModels;
