import { React, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import Swal from "sweetalert2";

import ApplicationInformation from "../ApplicationInformation";
import { useAuth } from "../../../context/AuthContext";
import NotFoundPage from "../../404page";

const ViewApplication = () => {
  const { applicationId } = useParams();
  const [application, setApplication] = useState(null);
  const { currentUser } = useAuth();
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [rejectJustification, setJustification] = useState("");

  const TopBar = ({ name }) => {
    return (
      <div className="flex flex-row mx-auto w-full my-8 justify-center">
        <h1 className="text-gray-800 font-bold text-2xl md:text-3xl">
          {name}'s Application
        </h1>
      </div>
    );
  };

  const handleRevoke = async () => {
    setVisibleConfirm(false);
    if (application) {
      const body = {
        application,
        email: currentUser.email,
      };
      const res = await axios.put("/api/application/revoke", body);
      if (res.status !== 404 || res.status !== 500) {
        Swal.fire({
          title: "Success!",
          text: res.data,
          icon: "success",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          customClass: {
            confirmButton:
              "bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/datasets"; // Navigate to the home page
          }
        });
        // alert(res.data);
        // navigate("/datasets");
      }
    }
  };

  useEffect(() => {
    const getSingleApplication = async () => {
      if (!applicationId) {
        console.error("Application ID is undefined.");
        return;
      }
      try {
        const res = await axios.get(`/api/application/id/${applicationId}`);
        setApplication(res.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    getSingleApplication();
  }, [applicationId]);

  if (!application) {
    // Could add a primereact loading spinner eventually
    return <div>Loading...</div>;
  }
  const showConfirmDialog = () => {
    setVisibleConfirm(true);
  };

  return application && currentUser ? (
    <div className="items-center py-20 px-4 md:px-32">
      <div className="flex justify-between items-center">
        <Link
          to="/profile"
          className="text-blue-500 hover:text-blue-700 p-2 hover:scale-105 transition duration-100"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-10 h-10"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5 8.25 12l7.5-7.5"
            />
          </svg>
        </Link>
        {application?.submittedInfo?.orgName ? (
          <TopBar name={application.submittedInfo.orgName} />
        ) : (
          <TopBar
            name={
              application?.submittedInfo?.adminFirstName +
              " " +
              application?.submittedInfo?.adminLastName
            }
          />
        )}
      </div>
      <ApplicationInformation
        application={application}
        applicationId={applicationId}
        setJustification={setJustification}
      />
      <Dialog
        visible={visibleConfirm}
        header="Delete Confirmation"
        style={{ width: "500px", textAlign: "center" }}
        onHide={() => setVisibleConfirm(false)}
        footer={
          <div>
            <Button
              label="No"
              onClick={() => setVisibleConfirm(false)}
              className="w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-red-500 text-base text-white hover:bg-red-700 sm:ml-3 sm:w-auto sm:text-sm"
            />
            <Button
              className="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-green-500 text-base text-white hover:bg-green-700 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              label="Yes"
              onClick={() => handleRevoke()}
            />
          </div>
        }
      >
        {/* Centered header */}
        <p>
          Are you sure you want to delete application? This action cannot be
          undone. Please confirm your decision
        </p>
      </Dialog>
      {currentUser?.email === application?.submitterEmail ? (
        <>
          <button
            className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            // onClick={() => handleRevoke()}
            onClick={() => showConfirmDialog()}
          >
            Delete Application
          </button>
        </>
      ) : (
        <button
          className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          // onClick={() => handleRevoke()}
          onClick={() => showConfirmDialog()}
        >
          Revoke Application
        </button>
      )}
    </div>
  ) : (
    <NotFoundPage />
  );
};

export default ViewApplication;
