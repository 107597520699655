import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { ProgressSpinner } from "primereact/progressspinner";
import { useQuery } from "@tanstack/react-query";
import { Calendar } from "primereact/calendar";
import React, { useState } from "react";
import { Toast } from "primereact/toast";

const TopBar = () => {
  return (
    <div className="flex flex-row mx-auto w-full my-8 justify-center py-4">
      <h1 className="text-gray-800 font-bold text-2xl md:text-3xl">
        Applications
      </h1>
    </div>
  );
};
const fetchApplications = async () => {
  const res = await axios.get("/api/application/allapplications");
  return res.data;
};

const fetchAdminDetails = async (email) => {
  const res = await axios.get(`/api/data/admin/${email}`);
  return res.data;
};

const ApplicationList = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filteredData, setFilteredData] = useState(null);

  const { data: adminDetails, isLoading: isAdminLoading } = useQuery({
    queryKey: ["adminDetails", currentUser?.email],
    queryFn: () => fetchAdminDetails(currentUser.email),
    enabled: !!currentUser?.email,
  });
  const { data: applications, isLoading: isApplicationsLoading } = useQuery({
    queryKey: ["applications", adminDetails],
    queryFn: fetchApplications,
    enabled: !!currentUser?.email && !!adminDetails,

    select: (data) => {
      let filteredApplications = [];
      if (adminDetails?.hitgenAdmin) {
        filteredApplications = data.filter(
          (app) => app.termsAndConditions.hitgenAccepted === true
        );
        filteredApplications = sortByAccepted(filteredApplications, "hitgen");
      } else if (adminDetails?.xchemAdmin) {
        filteredApplications = data.filter(
          (app) => app.termsAndConditions.xchemAccepted === true
        );
        filteredApplications = sortByAccepted(filteredApplications, "xchem");
      } else {
        filteredApplications = data;
        filteredApplications = sortByAccepted(filteredApplications, "xchem");
      }

      return filteredApplications;
    },
  });

  const filterByDateRange = (data, start, end) => {
    if (!data) return [];

    return data.filter((app) => {
      const applicationDate = new Date(app.dateSubmitted);
      const startDateTime = new Date(start);
      const endDateTime = new Date(end);

      // Set start date to beginning of day and end date to end of day
      startDateTime.setHours(0, 0, 0, 0);
      endDateTime.setHours(23, 59, 59, 999);

      return applicationDate >= startDateTime && applicationDate <= endDateTime;
    });
  };

  const toast = React.useRef(null);
  const isBothDatesSelected = startDate && endDate;
  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
    setIsFilterApplied(false);
    setFilteredData(null); // Reset to show original data
  };
  const filterApplication = () => {
    const dateFilteredData = filterByDateRange(
      applications,
      startDate,
      endDate
    );
    setFilteredData(dateFilteredData);
    setIsFilterApplied(true);
  };

  const handleRowClick = (rowData) => {
    navigate(`/single-application/${rowData.id}`);
  };

  const changeDateFormat = (dateStr) => {
    const date = new Date(dateStr);
    const year = date.getUTCFullYear();
    const monthIndex = date.getUTCMonth();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthName = monthNames[monthIndex];
    const day = String(date.getUTCDate()).padStart(2, "0"); // Pad with zero

    return `${year}-${monthName}-${day}`;
  };

  function sortByAccepted(applications, provider) {
    const decisionField =
      provider === "xchem" ? "xchemAccepted" : "hitgenAccepted";

    return applications.sort((a, b) => {
      const aDecision = a.decision[decisionField];
      const bDecision = b.decision[decisionField];

      // First, compare the provider's decision
      if (aDecision === bDecision) {
        // If decisions are the same, sort by dateSubmitted (most recent first)
        return new Date(b.dateSubmitted) - new Date(a.dateSubmitted);
      }

      // null comes first
      if (aDecision === null) return -1;
      if (bDecision === null) return 1;

      // false comes before true
      return aDecision - bDecision;
    });
  }
  if (!currentUser) {
    navigate("/datasets");
  }

  if (isAdminLoading || isApplicationsLoading) {
    return (
      <div className="h-screen flex justify-content-center items-center">
        <ProgressSpinner
          style={{ width: "250px", height: "250px" }}
          strokeWidth="6"
        />
      </div>
    );
  }
  const displayData = isFilterApplied ? filteredData : applications;

  return (
    <div className="w-full bg-white py-36 md:my-12 md:py-12">
      <div className="container mx-auto pl-8 pr-4 md:pl-20 md:pr-16">
        <TopBar />
        {/* <div className="bg-[#3e6f9f] rounded-lg shadow-lg p-8"> */}
        <div className="flex flex-col md:flex-row md:items-center gap-4">
          <div className="flex flex-col md:flex-row md:items-center gap-4">
            <div className="flex flex-col">
              <label className="mb-2 text-gray-700 font-medium">
                Start Date:
              </label>
              <Calendar
                value={startDate}
                onChange={(e) => setStartDate(e.value)}
                readOnlyInput
                className="border-solid border-stone-800 bg-gray-100 border-2 shadow rounded px-4 mb-6 w-full md:w-auto"
                showIcon
              />
            </div>

            <div className="flex flex-col">
              <label className="mb-2 text-gray-700 font-medium">
                End Date:
              </label>
              <Calendar
                value={endDate}
                onChange={(e) => setEndDate(e.value)}
                readOnlyInput
                className="border-solid border-stone-800 bg-gray-100 border-2 shadow rounded mb-6 w-full md:w-auto px-4"
                showIcon
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-2 md:self-end">
            <button
              className="bg-blue-500 hover:bg-blue-700 mb-6 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={filterApplication}
              disabled={!isBothDatesSelected}
            >
              Filter Application
            </button>

            <button
              className="bg-gray-500 hover:bg-gray-700  text-white mb-6 py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={handleClear}
              disabled={!isBothDatesSelected}
            >
              Clear
            </button>
          </div>
          <Toast ref={toast} />
        </div>
        <DataTable
          value={displayData}
          paginator
          rows={20}
          rowsPerPageOptions={[20, 30, 50, 100]}
          onRowClick={(event) => handleRowClick(event.data)}
        >
          <Column
            field="submittedInfo.orgName"
            header="Organization"
            alignHeader="center"
            sortable
            body={(rowData) => {
              const isIndividual = !rowData.submittedInfo.orgName;
              return (
                <span style={{ color: isIndividual ? "#8971d0" : "#323232" }}>
                  {isIndividual
                    ? "Individual Application"
                    : rowData.submittedInfo.orgName}
                </span>
              );
            }}
          />
          <Column
            field="submittedInfo.useDescription"
            header="Description"
            alignHeader={"center"}
          />
          <Column
            field="dateSubmitted"
            header="Submitted Date"
            align={"center"}
            body={(rowData) => {
              const formattedDate = changeDateFormat(rowData.dateSubmitted);
              return formattedDate;
            }}
            sortable
          />
          <Column
            field="submittedInfo.adminFirstName"
            header="Submitter Name"
            body={(rowData) => {
              return rowData.submittedInfo
                ? `${rowData.submittedInfo.adminFirstName} ${rowData.submittedInfo.adminLastName}`
                : "";
            }}
            alignHeader={"center"}
            sortable
          />
          <Column
            field="submitterEmail"
            header="Submitter Email"
            alignHeader={"center"}
            sortable
          />
          {(adminDetails?.superAdmin || adminDetails?.hitgenAdmin) && (
            <Column
              body={(rowData) => (
                <span
                  className={`w-full flex items-center justify-center text-center text-sm md:mb-0 ${
                    rowData.termsAndConditions.hitgenAccepted
                      ? rowData.decision.hitgenAccepted
                        ? "text-green-500"
                        : "text-red-500"
                      : "text-gray-500"
                  }`}
                >
                  {rowData.termsAndConditions.hitgenAccepted ? (
                    rowData.decision.hitgenAccepted !== null ? (
                      rowData.decision.hitgenAccepted === true ? (
                        <b>Accepted</b>
                      ) : rowData.decision.hitgenAccepted === false ? (
                        <b>Rejected </b>
                      ) : (
                        String(rowData.decision.hitgenAccepted)
                      )
                    ) : (
                      "No Decision Made"
                    )
                  ) : (
                    "N/A"
                  )}
                </span>
              )}
              header="HitGen Status"
              alignHeader={"center"}
            />
          )}
          {(adminDetails?.superAdmin || adminDetails?.xchemAdmin) && (
            <Column
              body={(rowData) => (
                <span
                  className={`w-full flex items-center justify-center text-center text-sm md:mb-0 ${
                    rowData.termsAndConditions.xchemAccepted
                      ? rowData.decision.xchemAccepted
                        ? "text-green-500"
                        : "text-red-500"
                      : "text-gray-500"
                  }`}
                >
                  {rowData.termsAndConditions.xchemAccepted ? (
                    rowData.decision.xchemAccepted !== null ? (
                      rowData.decision.xchemAccepted === true ? (
                        <b>Accepted </b>
                      ) : rowData.decision.xchemAccepted === false ? (
                        <>
                          <b>Rejected</b>
                        </>
                      ) : (
                        String(rowData.decision.xchemAccepted)
                      )
                    ) : (
                      "No Decision Made"
                    )
                  ) : (
                    "N/A"
                  )}
                </span>
              )}
              header="X-Chem Status"
              alignHeader={"center"}
            />
          )}
        </DataTable>
      </div>
    </div>
  );
};

export default ApplicationList;
