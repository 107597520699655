import React, { useState } from "react";
// import { Card } from "primereact/card";
import { Card as BaseCard } from "primereact/card";

import { Chart } from "primereact/chart"; // For the plot
import { useQuery } from "@tanstack/react-query";
import data from "../../utils/constants/model_card.json";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const fetchModelStats = async ({ queryKey }) => {
  const [_, runId] = queryKey; // Extract runId from queryKey
  if (!runId) return null;

  try {
    const response = await axios.post("/api//model_serving/mlstats", {
      runId: runId,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching model stats:", error);
    throw error;
  }
};

const Card = ({
  title,
  children,
  className,
  isOptional = false,
  colorScheme = "blue",
}) => {
  // Color schemes for different card types
  const colorSchemes = {
    blue: "bg-blue-50 border-blue-200",
    green: "bg-green-50 border-green-200",
    amber: "bg-amber-50 border-amber-200",
    purple: "bg-purple-50 border-purple-200",
    gray: "bg-gray-50 border-gray-200",
  };

  // Header color schemes
  const headerColors = {
    blue: "bg-blue-500 text-white",
    green: "bg-green-500 text-white",
    amber: "bg-amber-500 text-white",
    purple: "bg-purple-500 text-white",
    gray: "bg-gray-500 text-white",
  };

  return (
    <div
      className={`rounded-lg border ${
        colorSchemes[colorScheme]
      } overflow-hidden ${className} ${isOptional ? "opacity-90" : ""}`}
    >
      <div
        className={`px-4 py-3 ${headerColors[colorScheme]} flex justify-between items-center`}
      >
        <h2 className="text-lg font-semibold">{title}</h2>
        {isOptional && (
          <span className="bg-white text-gray-700 text-xs px-2 py-1 rounded-full font-medium">
            Optional
          </span>
        )}
      </div>
      <div className="p-4">{children}</div>
    </div>
  );
};

const ModelCard = () => {
  const { runId, targetName } = useParams();

  const {
    data: dataStats,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["modelStats", runId],
    queryFn: fetchModelStats,
    enabled: !!runId,
    placeholderData: { result: [] },
    retry: false,

    select: (data) => {
      if (!data.result) return null;
      const filtered_data = data.result.filter((stat) => stat.value > 0.2);
      const label = filtered_data.map((stat) => stat.key);
      const data_val = filtered_data.map((stat) => stat.value);
      return {
        labels: label,
        datasets: [
          {
            label: "Model Metrics",
            backgroundColor: "rgba(179,181,198,0.2)",
            borderColor: "rgb(57, 70, 189)",
            pointBackgroundColor: "rgb(55, 106, 134)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(179,181,198,1)",
            data: data_val,
          },
        ],
      };
    },
  });

  if (isError) {
    Swal.fire({
      title: "Error!",
      text: "Failed to fetch model stats. Please try again later.",
      icon: "error",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      customClass: {
        confirmButton:
          "bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "/models";
      }
    });

    return null; // Prevent further rendering of the dialog
  }

  if (!data || !data[targetName]) {
    return <div className="text-center p-8">No model data available</div>;
  }

  const modelData = data[targetName];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 mx-auto mt-24 gap-6 max-w-5xl w-full">
      {/* Mandatory Cards */}
      <Card
        title="Model Overview"
        colorScheme="blue"
        className="shadow-lg hover:shadow-xl transition-shadow duration-300"
      >
        <div className="text-gray-700">
          {modelData?.model_details ? (
            <>
              <p className="font-semibold">
                <b>Model Name:</b>{" "}
                {modelData.model_summary || "DEL-ML Predictor"}
              </p>
              <p className="font-semibold">
                <b>Developer(s):</b> {modelData.developed_by || "AIRCHECK"}
              </p>

              <p>
                <b>Version:</b> {modelData.model_version || "1.0.0"}
              </p>
              <p>
                <b>Model Type:</b>{" "}
                {modelData?.model_details?.model_type || "Supervised Learning"}
              </p>

              <p>
                <b>Model Summary:</b> {modelData.model_summary || ""}
              </p>

              <p>{modelData?.model_details?.model_description?.description}</p>

              <p className="font-semibold mt-2">Key Features:</p>
              <ul className="list-disc list-inside">
                {modelData.model_details.model_description.list.map(
                  (item, index) => (
                    <li key={index}>{item}</li>
                  )
                )}
              </ul>

              <p>
                Last Updated: {modelData.last_updated || "February 20, 2025"}
              </p>
              <p className="font-semibold">
                License: {modelData.License || "AIRCHECK"}
              </p>
            </>
          ) : (
            <p>No model details available</p>
          )}
        </div>
      </Card>

      <Card
        title="Model Uses"
        colorScheme="green"
        className="shadow-lg hover:shadow-xl transition-shadow duration-300"
      >
        <div className="text-gray-700">
          {modelData?.uses ? (
            <>
              {/* Direct Use Section */}
              <div className="mb-4">
                <h3 className="font-semibold text-lg mb-2 text-green-700">
                  Direct Use
                </h3>
                <ul className="list-disc pl-5">
                  {modelData?.uses.direct_use.map((use, index) => (
                    <li key={index} className="mb-1">
                      {use}
                    </li>
                  ))}
                </ul>
              </div>

              {/* Downstream Use Section */}
              <div className="mb-4">
                <h3 className="font-semibold text-lg mb-2 text-green-700">
                  Downstream Use
                </h3>
                <ul className="list-disc pl-5">
                  {modelData?.uses.downstream_use.map((use, index) => (
                    <li key={index} className="mb-1">
                      {use}
                    </li>
                  ))}
                </ul>
              </div>

              {/* Out of Scope Use Section */}
              <div>
                <h3 className="font-semibold text-lg mb-2 text-green-700">
                  Out of Scope Use
                </h3>
                <ul className="list-disc pl-5">
                  {modelData?.uses.out_of_scope_use.map((use, index) => (
                    <li key={index} className="mb-1">
                      {use}
                    </li>
                  ))}
                </ul>
              </div>
            </>
          ) : (
            <p>No usage information available</p>
          )}
        </div>
      </Card>

      <Card
        title="Data Usage"
        colorScheme="amber"
        className="col-span-1 md:col-span-1 shadow-lg hover:shadow-xl transition-shadow duration-300"
      >
        {modelData.data_used ? (
          <div>
            <div>
              <p className="font-bold text-amber-700">Description:</p>
              <p>{modelData.data_used.description || "DEL-ML Predictor"}</p>
            </div>
            <div className="mt-3">
              <p className="font-bold text-amber-700">Training Data:</p>
              <ul className="list-disc list-inside">
                {modelData?.data_used?.training_data?.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
            <div className="mt-3">
              <p className="font-bold text-amber-700">Test Data:</p>
              <ul className="list-disc list-inside">
                {modelData?.data_used?.test_data?.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
        ) : (
          <p>No data usage information available</p>
        )}
      </Card>

      {/* Optional Cards */}
      {modelData.limitations && (
        <Card
          title="Limitations"
          isOptional={true}
          colorScheme="gray"
          className="col-span-1 md:col-span-1 shadow-lg hover:shadow-xl transition-shadow duration-300"
        >
          <div>
            <ul className="list-disc list-inside">
              {Object.entries(modelData?.limitations).map(([key, value]) => (
                <li key={key}>
                  <span className="font-bold text-purple-700">{key}:</span>{" "}
                  {value}
                </li>
              ))}
            </ul>
          </div>
        </Card>
      )}

      {dataStats && (
        <Card
          title="Model Metrics"
          colorScheme="blue"
          className="shadow-lg hover:shadow-xl transition-shadow duration-300 col-span-1 md:col-span-2"
        >
          <div className="space-y-4">
            <div className="h-[60vh]">
              <Chart
                type="radar"
                data={dataStats}
                options={{
                  scales: {
                    r: {
                      beginAtZero: true,
                      ticks: {
                        display: false,
                      },
                      pointLabels: {
                        display: false,
                      },
                    },
                  },
                  plugins: {
                    datalabels: {
                      color: "#000",
                      anchor: "center",
                      align: "top",
                      formatter: (value, context) => {
                        if (value > 0.2) {
                          const label =
                            context.chart.data.labels[context.dataIndex];
                          return `${label}\n${value.toFixed(2)}`;
                        }
                        return "";
                      },
                      font: {
                        size: 12,
                        weight: "bold",
                      },
                    },
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                }}
                style={{ height: "100%", width: "100%" }}
              />
            </div>
          </div>
        </Card>
      )}
      <Card
        title="Limitations"
        isOptional={true}
        colorScheme="gray"
        className="col-span-1 md:col-span-1 shadow-lg hover:shadow-xl transition-shadow duration-300"
      >
        {modelData.ethical_considerations && (
          <>
            <h3 className="font-bold text-xl mt-6 mb-3 text-purple-700">
              Ethical Considerations:
            </h3>
            <ul className="list-disc list-inside">
              {Object.entries(modelData?.ethical_considerations).map(
                ([key, value]) => (
                  <li key={key}>
                    <span className="font-bold">{key}:</span> {value}
                  </li>
                )
              )}
            </ul>
          </>
        )}
      </Card>

      {/* Optional Additional Details Card */}
      <Card
        title="Additional Details"
        isOptional={true}
        colorScheme="gray"
        className="shadow-lg hover:shadow-xl transition-shadow duration-300"
      >
        <div className="text-gray-700">
          <p className="font-semibold text-gray-700">Training Data:</p>
          <p>DEL Library Size: 3 M compounds</p>
          <p>Features: Molecular descriptors, binding affinities</p>
          <p>Training Time: 14 hours on GPU</p>
          <p className="mt-2 font-semibold text-gray-700">Usage Notes:</p>
          <p>Optimized for high-throughput screening</p>
          <p>Best performance on diverse chemical libraries</p>
        </div>
      </Card>
    </div>
  );
};

export default ModelCard;
