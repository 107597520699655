import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { BrowserRouter as Router, useLocation } from "react-router-dom";

import BasicRoutes from "./Routes";
// import Navbar from "./components/common/navbar/Navbar";
import { AuthProvider } from "./context/AuthContext";
// import Footer from "./components/common/footer/Footer";
import ErrorBoundary from "./components/error/ErrorBoundary";
const queryClient = new QueryClient();

function App() {
  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <BasicRoutes />
        </AuthProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

export default App;
