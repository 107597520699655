import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { PrivateRoutes, ApplicationRoute } from "./protectRoutes";
import {
  Home,
  Datasets,
  Application,
  ApplicationList,
  SingleApplication,
  NotFoundPage,
  ViewApplication,
  Readmd,
  FeedbackForm,
  Stats,
  DisplayModels,
  ExternalRedirect,
  PredictionUpload,
  About,
  ModelCard,
  ProfilePage,
} from "./pages/index";
import Navbar from "./components/common/navbar/Navbar";
import Footer from "./components/common/footer/Footer";

function BasicRoutes() {
  const location = useLocation();
  return (
    <>
      {location.pathname !== "/mainframe" && <Navbar />}

      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/datasets" element={<Datasets />} />
        <Route path="/models" element={<DisplayModels />} />
        <Route path="/stats" element={<Stats />} />
        <Route path="/feedbackform" element={<FeedbackForm />} />
        <Route path="/readme" element={<Readmd />} />
        <Route path="/mainframe" element={<ExternalRedirect />} />
        <Route element={<ApplicationRoute />}>
          <Route path="/application" element={<Application />} />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route path="/application-list" element={<ApplicationList />} />
          <Route path="/single-application/:applicationId" element={<SingleApplication />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/view-application/:applicationId" element={<ViewApplication />} />
        <Route path="/screening/:runId/:targetName" element={<PredictionUpload />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/modelcard/:runId/:targetName" element={<ModelCard />} />
        <Route path="/profile" element={<ProfilePage />} />
      </Routes>
      {location.pathname !== "/mainframe" && <Footer />}
    </>
  );
}

export default BasicRoutes;
