import React from "react";
import { Link } from "react-router-dom";
const Mainframe = () => {
  return (
    <div className="w-full bg-white ">
      <div className="container mx-auto md:py-8 flex flex-col items-start justify-between">
        <p className="text-[18pt] text-center md:text-[56pt] font-bold  text-black px-8 md:mb-8 md:pl-16 md:pr-12 leading-[1.1] md:leading-[1.2] lg:leading-[1.3] ">
          Join MAINFRAME to Get Early Access to AIRCHECK Datasets
        </p>
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
          <div className="w-full md:w-1/2 h-auto flex self-start">
            <img
              src="/images/new-logos/3_mainframe.png"
              alt="Icon for MAINFRAME"
              className="max-w-[100%] max-h-[100%] object-contain"
            />
          </div>

          {/* Text container */}
          <div className="w-full md:w-1/2 flex flex-col items-center text-center md:text-left px-8 md:py-8 md:pl-10 md:mr-16 ">
            <p className="text-[12pt] md:text-[24pt] mt-4 font-extralight text-black">
              MAINFRAME (MAchine Learning Innovation Network For Research to
              Advance MEdicinal Chemistry) is an international network of
              scientists dedicated to advancing pre-competitive drug discovery
              by promoting open-source algorithm development.
            </p>

            <Link
              to="/mainframe"
              target="_blank"
              className="text-lg sm:text-xl flex mt-12 font-bold bg-gradient-to-r from-[#e53201] to-[#f36548] text-white px-6 py-2 rounded-3xl  hover:bg-[#a2d2ff] hover:text-black transition-colors"
            >
              GET INVOLVED
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mainframe;
