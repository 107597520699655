import React, { useState, useRef } from "react";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { useForm, Controller } from "react-hook-form";
import axios from "axios"; // Missing import

import { useQuery } from "@tanstack/react-query";
import { ProgressSpinner } from "primereact/progressspinner";
import Swal from "sweetalert2"; // Import SweetAlert2
import { useParams } from "react-router-dom";

import { InputTextarea } from "primereact/inputtextarea";

import CompoundTable from "./ShowResult";

import ErrorPage from "../../components/error/Error";

const fetchPredictions = async ({ runId, mlflowUrl, file, textInput }) => {
  if (!runId || (!file && !textInput)) {
    throw new Error("Missing required parameters");
  }

  const formData = new FormData();
  formData.append("runId", runId);
  formData.append("mlflowUrl", mlflowUrl);
  if (file) {
    formData.append("file", file);
  }
  if (textInput) {
    formData.append("textInput", textInput);
  }
  try {
    const response = await axios.post(
      "/api//model_serving/predict_compounds",
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching model stats:", error);
    throw error;
  }
};

const TopBar = ({ title }) => {
  return (
    <div>
      <h1 className="text-black text-center font-bold text-2xl md:text-3xl">
        {title}
      </h1>
    </div>
  );
};

const PredictionUpload = () => {
  const { runId, targetName } = useParams();
  const fileUploadRef = useRef(null);
  const [queryParams, setQueryParams] = useState({
    runId: null,
    file: null,
    textInput: null,
  });

  // const [predictedResult, setPredictedResult] = useState("");

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      text: "",
      file: null,
    },
  });

  const {
    data: predictedResult,
    error,
    isLoading,
  } = useQuery({
    queryKey: [
      "predictions",
      queryParams.runId,
      queryParams.textInput,
      queryParams.file?.name,
      queryParams.file?.size,
    ],
    queryFn: () =>
      fetchPredictions({
        runId: queryParams.runId,
        file: queryParams.file,
        textInput: queryParams.textInput,
      }),
    // enabled: false,
    enabled: Boolean(
      queryParams.runId && (queryParams.file || queryParams.textInput)
    ),

    retry: false,
    onError: (error) => {
      console.error("Query error:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message || "An error occurred while fetching predictions.",
      });
    },
  });

  const textValue = watch("text");
  const file = watch("file");
  // Handle form submission
  const onSubmit = (form_data) => {
    // Display message if both fields are filled
    if (form_data.text && form_data.file) {
      alert("Both fields are filled!");
    }
    setQueryParams({
      runId: runId,
      file: form_data.file,
      textInput: form_data.text.trim(),
    });
  };

  const isSubmitDisabled = (textValue && file) || (!textValue && !file);

  if (error) {
    const errorMessage =
      error.response.status === 400
        ? "No SMILE/SMILES column found in the CSV file"
        : "Error Fetching data";

    Swal.fire({
      title: "Error!",
      text: ` ${errorMessage}. Please try again later.`,
      icon: "error",
      confirmButtonText: "Ok",
      allowOutsideClick: false,
      customClass: {
        confirmButton:
          "bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = "/models";
      }
    });
    return <ErrorPage error={error} />;
  }

  return (
    <>
      {/* <div className="container h-screen mx-auto pl-8 pr-4 md:pl-20 md:pr-16">
        <div className="mx-auto mt-36 md:mt-[5.5rem] text-black px-4 py-8"> */}
      <div className="container h-screen mx-auto px-8 pt-48 md:pt-36 px-8 py-16 md:px-16 md:py-32">
        <TopBar title={"Predict your compound using our model"} />
        <div className="p-4">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
            <div className="flex-1 container mx-auto px-4 py-2">
              <label htmlFor="text" className="font-semibold">
                Enter SMILES/ Compounds
                <span className="text-xs text-black block">
                  (Please enter a SMILES string or a compound name. Expected
                  format: a single SMILES string or multiple SMILES strings
                  separated by commas (e.g., compound1, compound2,..))
                </span>
              </label>

              <div className="w-full md:w-3/4">
                <Controller
                  name="text"
                  control={control}
                  rules={{ required: !file }} // Text is required if no file is uploaded
                  render={({ field }) => (
                    <InputTextarea
                      {...field}
                      id="text"
                      placeholder="Enter Compound"
                      onChange={(e) => {
                        const sanitizedValue = e.target.value.replace(
                          /['"`]/g,
                          ""
                        );
                        field.onChange(sanitizedValue);
                      }}
                      className={`border rounded w-3/4 py-2 px-1 bg-white text-green-800 font-extrabold ${
                        errors.text ? "border-red-500" : ""
                      }`}
                    />
                  )}
                />

                {errors.text && (
                  <span className="text-red-500 text-sm">
                    Compound is required.
                  </span>
                )}
              </div>
            </div>

            {/* File Upload Field */}
            {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-1"> */}
            <div className="flex-1 container mx-auto px-4 py-2">
              <label htmlFor="file" className="font-semibold self-center">
                SMILES File
                <span className="text-xs text-black block">
                  (Please upload SMILES files in CSV format, make sure the CSV
                  file contains a header named 'SMILE' or 'SMILES.)
                </span>
              </label>
              <div className="w-full md:w-3/4">
                <Controller
                  name="file"
                  control={control}
                  rules={{ required: !textValue }} // File is required if no text is entered
                  render={({ field: { onChange, value, ...field } }) => {
                    const handleClear = () => {
                      onChange(null); // Clear the form value
                      if (fileUploadRef.current) {
                        fileUploadRef.current.clear(); // Clear the FileUpload component's internal state
                      }
                    };

                    return (
                      <div className="flex items-center gap-2">
                        <FileUpload
                          {...field}
                          ref={fileUploadRef} // Attach the ref to the FileUpload component
                          mode="basic"
                          name="file"
                          accept=".csv,.doc,.docx,.txt"
                          maxFileSize={1000000} // 1MB limit
                          onSelect={(e) => {
                            const file = e.files[0];
                            onChange(file);
                          }}
                          onClear={() => onChange(null)}
                          chooseLabel="Upload File"
                          className={`p-2 border rounded ${
                            errors.file ? "border-red-500" : ""
                          }`}
                        />
                        {value && (
                          <button
                            type="button"
                            onClick={handleClear} // Use the handleClear function
                            className="px-3 py-1 text-sm font-semibold text-white bg-red-500 rounded hover:bg-red-600"
                          >
                            Clear
                          </button>
                        )}
                      </div>
                    );
                  }}
                />
                {errors.file && (
                  <span className="text-red-500 text-sm">
                    File is required.
                  </span>
                )}
              </div>
            </div>

            {textValue && file && (
              <div className="text-yellow-600 text-sm">
                Both fields are filled. Only one field is required.
              </div>
            )}

            <div className="flex justify-center mt-6">
              <Button
                type="submit"
                label="Submit"
                disabled={isSubmitDisabled}
                className={`text-lg sm:text-xl flex mt-12 font-bold bg-gradient-to-r from-[#e53201] to-[#f36548] text-white px-6 py-2 rounded-3xl  hover:bg-[#a2d2ff] hover:text-black transition-colors ${
                  isSubmitDisabled ? "opacity-50 cursor-not-allowed" : ""
                }`}
              />
            </div>
          </form>

          {isLoading && (
            <div className="flex justify-center mt-8">
              <ProgressSpinner />
            </div>
          )}
          {predictedResult && (
            <CompoundTable predictedResult={predictedResult} />
          )}
        </div>
      </div>
    </>
  );
};

export default PredictionUpload;
