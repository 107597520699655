import React from "react";
// import { Link } from "react-router-dom";
// import CumulativeBarChart from "./Chart";
const Info = () => {
  return (
    <div className="w-full  bg-[#06002e] shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1),0_-2px_4px_-1px_rgba(0,0,0,0.06)] my-12 md:py-24 ">
      <div className="container mx-auto py-16 px-8 md:pl-20  flex flex-col md:flex-row items-center justify-between md:pr-16">
        {/* Image container - fixed size independent of text */}
        <div className="w-full h-auto flex items-center justify-center">
          <p className="font-extralight text-center text-[18pt] md:text-[56pt] text-white">
            AIRCHECK removes barriers and scales AI-driven drug discovery with
            high-quality, experimental data and open predictive models.
          </p>
        </div>

        {/* Text container */}
        {/* <div className="w-full md:w-2/3 flex flex-col">
          <CumulativeBarChart />
        </div> */}
      </div>
    </div>
  );
};

export default Info;
