import { React, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import About from "./About";
import Partner from "./Partners";
import DatabaseDetails from "./Database_Details";
import DataTypes from "./Data_Types";
import AircheckStacks from "./Aircheck_Stack";
import Mainframe from "./Mainframe";
// import CumulativeBarChart from "./Chart";
import Info from "./Info";

const NewHome = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.state?.scrollToAbout) {
      const aboutElement = document.getElementById("about");
      if (aboutElement) {
        const navbarHeight = document.querySelector("nav")?.offsetHeight || 80;
        const aboutPosition =
          aboutElement.getBoundingClientRect().top + window.scrollY;

        // Small delay to ensure the page is fully loaded
        setTimeout(() => {
          window.scrollTo({
            top: aboutPosition - navbarHeight,
            behavior: "smooth",
          });
        }, 100);
      }

      // Clean up state
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state, location.pathname, navigate]);
  return (
    <>
      <div className="w-full mt-[10rem] md:mt-[5.5rem]">
        <div className="w-full bg-[#06002e] pb-16 md:pb-24">
          <div className="container  mx-auto  flex flex-col  items-start justify-between">
            <div className="hidden md:flex w-full pl-8 md:pl-16 pt-12 pb-2  items-start ">
              <img
                alt="Aircheck Logo"
                className="w-full max-w-[400px] md:max-w-[800px] h-auto"
                // className="w-full  object-cover"
                // src="/images/new-logos/aircheck_logo.png"
                src="/images/new-logos/new_aircheck_logo.png"
                sizes="100vw"
                style={{ color: "transparent" }}
              />
            </div>
            <div className="w-full flex pl-8 md:pl-20 flex-col text-center">
              <p className="text-[16pt] md:text-[40pt] text-white pr-8 py-4 text-left text-pretty">
                MAKING HIGH-QUALITY, <br />
                STANDARDIZED DATA ACCESSIBLE <br /> TO RESEARCHERS EVERYWHERE
              </p>
              <p className="text-[12pt] md:text-[21pt] pr-8  text-[#f56c4f] md:py-2  text-left text-pretty">
                ML-ready datasets designed to support AI-driven small molecule
                discovery.
              </p>
              <div className="flex flex-row">
                <div className="mt-4 pr-4">
                  <button
                    target="_blank"
                    className="text-sm md:text-xl flex  bg-gradient-to-r from-[#e53201] to-[#f36548] text-white px-4 py-2 rounded-3xl  hover:bg-[#a2d2ff] transition-colors"
                    href="/datasets"
                    onClick={() => (window.location.href = "/datasets")}
                  >
                    <p className="font-bold">OUR DATASETS</p>
                  </button>
                </div>
                <div className="mt-4  ml-4">
                  <button
                    target="_blank"
                    className="text-sm md:text-xl flex bg-gradient-to-r from-[#e53201] to-[#f36548] text-white px-4 py-2 rounded-3xl transition ease-in-out delay-150 hover:scale-110 transition-colors"
                    onClick={() => (window.location.href = "/models")}
                  >
                    <p className="font-bold">OUR MODELS</p>
                  </button>
                </div>
              </div>
            </div>

            {/* Right Half: Image */}
          </div>
        </div>

        <About />
        <DatabaseDetails />
        <Info />

        <DataTypes />
        <AircheckStacks />

        <Mainframe />
        <Partner />
        {/* <CumulativeBarChart /> */}
      </div>
    </>
  );
};

export default NewHome;
