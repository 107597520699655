import React from "react";

const Partner = () => {
  // const partners = [
  //   { name: "SGC", src: "/images/logos/sgc-logo-reverse.png" },
  //   { name: "UHN", src: "/images/icons/uhn.png" },
  //   { name: "Google", src: "/images/icons/google_partner.png" },
  //   { name: "UNC", src: "/images/icons/unc.png" },
  //   { name: "Temerty", src: "/images/icons/temerty.png" },
  //   { name: "Xchem", src: "/images/logos/new-xchem-logo.png" },
  //   { name: "HitGen", src: "/images/logos/HitGen_Logo.jpg" },
  // ];

  // const companies = [
  //   { name: "SGC", src: "/images/icons/partners/sgc-logo-reverse.png" },
  //   { name: "UHN", src: "/images/icons/partners/uhn.png" },
  //   { name: "Google", src: "/images/icons/partners/google_partner.png" },
  //   // { name: "UNC", src: "/images/icons/partners/unc.png" },
  // ];

  // const academics = [
  //   { name: "UNC", src: "/images/icons/partners/unc.png" },
  //   { name: "temerty", src: "/images/icons/partners/temerty.png" },
  // ];

  // const data_partner = [
  //   { name: "XCHEM", src: "/images/icons/partners/new-xchem-logo.png" },
  //   { name: "HITGEN", src: "/images/icons/partners/HitGen_Logo.png" },
  //   { name: "Enamine", src: "/images/icons/partners/enamine.png" },
  // ];

  return (
    <>
      <div className="w-full bg-white shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1),0_-2px_4px_-1px_rgba(0,0,0,0.06)] my-16 md:my-16 py-16  ">
        <div className="container mx-auto flex flex-col items-center justify-center">
          {/* Partners Text Section */}
          <div className="w-full text-center mb-12">
            <h2 className="text-[18pt] md:text-[56pt] font-bold text-black mb-4">
              Partners
            </h2>
            <p className="text-[12pt] md:text-[24pt] font-extralight text-black px-8  md:px-12  lg:pl-16 lg:pr-16">
              A global effort between industry and academia to expand data
              access and democratize AI-driven drug discovery for all.
            </p>
          </div>

          {/* Partner Logos Section */}
          <div className="w-full">
            <div className="w-full flex justify-center">
              <div className="flex flex-wrap max-w-7xl">
                <div className="flex items-center w-full mr-4">
                  <img
                    alt="aircheck partners"
                    src="images/new-logos/AIRCHECK_Partners_new.png"
                    className="object-contain w-full max-w-screen-lg h-auto"
                  />
                </div>
              </div>
            </div>
            {/* MIGHT BE USEFUL WHEN LOADING EACH PARTNERS DYNAMICALLY FROM IMAGE PROVIDED */}
            {/* <div className="w-full flex justify-center">
              <div className="flex flex-wrap gap-8 md:gap-20 max-w-7xl">
                {companies.map((partner, index) => (
                  <div
                    key={`company-${index}`}
                    className="flex items-center justify-center md:gap-40 justify-center w-80 h-60"
                  >
                    <img
                      alt={partner.name}
                      src={partner.src}
                      className={`object-contain p-4 ${
                        partner.name === "UNC"
                          ? "!w-44 !h-80" // Force fixed size
                          : "max-w-full max-h-full" // Allow dynamic size for others
                      }`}
                      width={partner.name === "UNC" ? 128 : undefined}
                      height={partner.name === "UNC" ? 96 : undefined}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="w-full flex justify-center">
              <div className="flex flex-wrap gap-8 md:gap-40 max-w-7xl">
                {academics.map((partner, index) => (
                  <div
                    key={`education-${index}`}
                    className="flex items-center justify-center w-72 h-60"
                  >
                    <img
                      alt={partner.name}
                      src={partner.src}
                      className={`object-contain max-w-full max-h-full p-4 ${
                        partner.name === "UNC"
                          ? "!w-44 !h-80" // Force fixed size
                          : "max-w-full max-h-full" // Allow dynamic size for others
                      }`}
                      width={partner.name === "UNC" ? 128 : undefined}
                      height={partner.name === "UNC" ? 96 : undefined}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="w-full flex justify-center">
              <div className="flex flex-wrap gap-8 md:gap-24   max-w-7xl">
                {data_partner.map((partner, index) => (
                  <div
                    key={`data-${index}`}
                    className="flex items-center justify-center w-100 h-100"
                  >
                    <img
                      alt={partner.name}
                      src={partner.src}
                      className="object-contain !w-64 !h-60"
                      width={partner.name === "XCHEM" ? 128 : 128}
                      height={partner.name === "XCHEM" ? 96 : 96}
                    />
                  </div>
                ))}
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Partner;
